import { Injectable } from '@angular/core';
import { FormGroup, ValidatorFn } from '@angular/forms';
import { DataService } from './data.service';
import {
    INumberValidator,
    IWholeNumberValidator,
    INumericMaxValueValidator,
    INumericMinValueValidator,
    INumericRangeValidator,
    IRequiredValidator,
    IEmptyValidator,
    IMaxLengthValidator,
    IPatternValidator,
    IValidationRules,
    IValidator,
    ICurrentControlValidators,
    IfutureDateValidator,
    IpastDateValidator,
    IDateMaxValueValidator,
    IDateMinValueValidator,
    IAfterAnotherDateValidator,
    IGreaterThanAnotherValidator,
    ILessThanAnotherValidator
} from './validation.models';
import { ControlValidators } from './control-validators.directive';

@Injectable({
    providedIn: 'root'
  })
export class ValidationService {

    constructor(private dataService: DataService) { }

    applyValidationRules(formGroup: FormGroup,componentName:any) {
        let validationRules:IValidationRules = {} as any;
        return new Promise<Array<ICurrentControlValidators>>((resolve) => {
            validationRules = this.dataService.getRules()
            resolve(this.addRulesToControls(formGroup, validationRules[componentName]));
        });
    }

    // this function will build validation rules to fieldName as per the JSON Data 
    protected addRulesToControls(formGroup: FormGroup, validationRules) {
        const controlValidators = [];
        if (validationRules) {
            // here we are repeating for all jsondata fields and not on form controls
            // because the form may also have controls which doesn not require validators
            for (const prop of validationRules) {
                //"this.buildFieldValidators" function will return the each validator AbstractControl function
                const validatorFunctionArray = this.buildFieldValidators(prop.rules);

                if (validatorFunctionArray.length > 0) {
                    // storing each field validator and control functions in "validators",and pushing them to "controlValidators"
                    const validators = this.applyValidatorToControl(formGroup, prop.fieldName, validatorFunctionArray);
                    if (validators) {
                        controlValidators.push(validators);
                    }
                }
            }
        }
        return controlValidators;
    }

    private buildFieldValidators(rules: Array<IValidator>): any[] {
        const validatorFunctionArray: Array<Function> = [];
        for (const rule of rules) {
            switch (rule.type) {
                case 'numberValidator':
                    validatorFunctionArray.push(ControlValidators.numberValidator((<INumberValidator>rule).numberValidator.errorMessage));
                    break;
                case 'wholeNumberValidator':
                    validatorFunctionArray.push(ControlValidators.wholeNumberValidator((<IWholeNumberValidator>rule).wholeNumberValidator.errorMessage));
                    break;
                case 'requiredValidator':
                    validatorFunctionArray.push(ControlValidators.requiredValidator((<IRequiredValidator>rule).requiredValidator.errorMessage));
                    break;
                    case 'emptyValidator':
                    validatorFunctionArray.push(ControlValidators.emptyValidator((<IEmptyValidator>rule).emptyValidator.errorMessage));
                    break;
                case 'numericMinValueValidator':
                    validatorFunctionArray.push(ControlValidators.minValueValidator(
                        (<INumericMinValueValidator>rule).numericMinValueValidator.minValue,
                        (<INumericMinValueValidator>rule).numericMinValueValidator.inclusive,
                        (<INumericMinValueValidator>rule).numericMinValueValidator.errorMessage));
                    break;
                case 'numericMaxValueValidator':
                    validatorFunctionArray.push(ControlValidators.maxValueValidator(
                        (<INumericMaxValueValidator>rule).numericMaxValueValidator.maxValue,
                        (<INumericMaxValueValidator>rule).numericMaxValueValidator.inclusive,
                        (<INumericMaxValueValidator>rule).numericMaxValueValidator.errorMessage));
                    break;
                case 'numericRangeValidator':
                    validatorFunctionArray.push(ControlValidators.numericRangeValidator(
                        (<INumericRangeValidator>rule).numericRangeValidator.minValue,
                        (<INumericRangeValidator>rule).numericRangeValidator.maxValue,
                        (<INumericRangeValidator>rule).numericRangeValidator.allowZero,
                        (<INumericRangeValidator>rule).numericRangeValidator.inclusive,
                        (<INumericRangeValidator>rule).numericRangeValidator.errorMessage));
                    break;
                case 'maxLengthValidator':
                    validatorFunctionArray.push(ControlValidators.maxLengthValidator(
                        (<IMaxLengthValidator>rule).maxLengthValidator.maxLength,
                        (<IMaxLengthValidator>rule).maxLengthValidator.errorMessage));
                    break;
                case 'patternValidator':
                    validatorFunctionArray.push(ControlValidators.patternValidator(
                        (<IPatternValidator>rule).patternValidator.pattern,
                        (<IPatternValidator>rule).patternValidator.errorMessage));
                    break;
                case 'futureDateValidator':
                    validatorFunctionArray.push(ControlValidators.futureDateValidator(
                        (<IfutureDateValidator>rule).futureDateValidator.errorMessage));
                    break;
                case 'pastDateValidator':
                    validatorFunctionArray.push(ControlValidators.pastDateValidator(
                        (<IpastDateValidator>rule).pastDateValidator.errorMessage));
                    break;
                case 'afterAnotherDateValidator':
                    validatorFunctionArray.push(ControlValidators.afterAnotherDateValidator(
                        (<IAfterAnotherDateValidator>rule).afterAnotherDateValidator.controlKey,
                        (<IAfterAnotherDateValidator>rule).afterAnotherDateValidator.inclusive,
                        (<IAfterAnotherDateValidator>rule).afterAnotherDateValidator.errorMessage));
                    break;
                case 'dateMaxValueValidator':
                    validatorFunctionArray.push(ControlValidators.maxDateValidator(
                        (<IDateMaxValueValidator>rule).dateMaxValueValidator.maxValue,
                        (<IDateMaxValueValidator>rule).dateMaxValueValidator.inclusive,
                        (<IDateMaxValueValidator>rule).dateMaxValueValidator.errorMessage));
                    break;
                case 'dateMinValueValidator':
                    validatorFunctionArray.push(ControlValidators.minDateValidator(
                        (<IDateMinValueValidator>rule).dateMinValueValidator.minValue,
                        (<IDateMinValueValidator>rule).dateMinValueValidator.inclusive,
                        (<IDateMinValueValidator>rule).dateMinValueValidator.errorMessage));
                    break;
                case 'greaterThanAnotherValidator':
                    validatorFunctionArray.push(ControlValidators.greaterThanAnotherValidator(
                        (<IGreaterThanAnotherValidator>rule).greaterThanAnotherValidator.controlKey,
                        (<IGreaterThanAnotherValidator>rule).greaterThanAnotherValidator.inclusive,
                        (<IGreaterThanAnotherValidator>rule).greaterThanAnotherValidator.errorMessage));
                    break;
                case 'lessThanAnotherValidator':
                    validatorFunctionArray.push(ControlValidators.lessThanAnotherValidator(
                        (<ILessThanAnotherValidator>rule).lessThanAnotherValidator.controlKey,
                        (<ILessThanAnotherValidator>rule).lessThanAnotherValidator.inclusive,
                        (<ILessThanAnotherValidator>rule).lessThanAnotherValidator.errorMessage));
                    break;
            }
        }

        return validatorFunctionArray;
    }

    // this function will apply the validators to each formcontrol 
    private applyValidatorToControl(formGroup: FormGroup, controlName: string,
        validatorFunctionArray: any[]): ICurrentControlValidators {
        const fromControl = formGroup && formGroup.controls ? formGroup.controls[controlName] : null;
        if (fromControl) {
            // if formcontrol value is not null set validators to the formcontrol 
            fromControl.setValidators(validatorFunctionArray);
            fromControl.updateValueAndValidity();
            return {
                controlName:controlName,
                control: fromControl,
                validators: validatorFunctionArray
            };
        }
        return null;
    }

    markFormGroupTouched(formGroup: FormGroup) {
        (<any>Object).values(formGroup.controls).forEach(control => {
          control.markAsTouched();
    
          if (control.controls) {
            this.markFormGroupTouched(control);
          }
        });
      }

      validateKycDocumentNumber(documentNumber, selectedProofType, listOfProofDocumentTypes) {
        let selectedDocument = listOfProofDocumentTypes.find(
            proofDocumentType => proofDocumentType.code === selectedProofType
        );
        
        // validation of documentNumber is done only if document number is present and value is present in field2
        // hence if documentNumber not present or field2 does not have regex value then we are returning true,
        // if documentNumber is matching with regexPattern then we are returning true.
        // only if there is no match with regexPattern in field2 we are returning false.
        if (selectedDocument && selectedDocument.field2 && selectedDocument.field2.trim()) {
            // if regex itself is not a valid regular expression pattern in field2, then we are not validation we are just proceeding further 
            if(!this.isValidRegexPattern(selectedDocument.field2)){
                return true;
            }
            let docNumber = documentNumber ? documentNumber.trim() : '';
            if (!docNumber) {
                return true;
            }
            // listOfProofDocumentTypes field2 has regex pattern for document number, "match" is a inbuilt method that will find the matching with regex patter
            if (docNumber.match(selectedDocument.field2)) {
                return true;
            } else {
                return false;
            }
        }

        return true;
    }

    isValidRegexPattern(pattern: string): boolean {
        try {
            new RegExp(pattern);
            return true;
        } catch (e) {
            return false;
        }
    }
}