import { Component, OnInit, Input, Inject } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { LoanApplication, ApplicationAccessSpecifier } from '@app/applications/applications.model';
import { NameValueDto } from '@ig-core/interfaces/name-value-dto';
import { ApplicationService } from '@app/applications/application.service';
import { ReferenceCodeService } from '@app/admin/reference-code/reference-code.service';
import { KycProof, KycProofType } from './kyc.model';
import { DateAdapter, MatSnackBar } from '@angular/material';
import { BusinessEntity } from '@app/leads/leads.model';
import { ReferenceCode } from '@app/admin/reference-code/reference-code.model';
import { IgFileService, Files } from '@ig-core/form/igFile.service';
import { ValidationService } from '../../../application-validators/validation.service';
import { ImagePreviewDialogService } from '@app/utils/image-preview-dialog/image-preview-dialog.service';
import { FileUploadService } from '@app/utils/file-upload/file-upload.service';
import { ControlValidators } from '../../../application-validators/control-validators.directive';
import { DateFormatPipe } from '@app/utils/date-format.pipe';
import { File, Folder, FolderFilesComposite } from '@app/applications/application-details/loanInformation/loan-folder-files/loan-folder-files.model';
import { ConfirmationDialogService } from '@app/utils/confirmation-dialog/confirmation-dialog.service';
import * as fileSaver from 'file-saver';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
@Component({
  selector: 'eng-kyc-proofs',
  templateUrl: 'kyc-proofs.template.html',
  styleUrls: ['../../application-details.styles.scss', './kyc-proofs.style.scss']
})
export class KYCProofsComponent implements OnInit {

  @Input()
  application: LoanApplication;

  @Input()
  business: BusinessEntity;
  @Input()
  menuCode: string;

  myThumbnail: any
  kycProofs: KycProof[] = [];
  selectedKyc: KycProof;
  kycProofTypes: KycProofType[] = [];

  kycForm: FormGroup = new FormGroup({});

  businessProofDocumentTypes: ReferenceCode[] = [];
  businessProofTypes: NameValueDto[] = [];
  allFilesInFolder: FolderFilesComposite;

  kycImage: any;

  isKycValidityFormLoaded: boolean;

  isFormDisabled: boolean;
  showForm: boolean;
  allowAccess: Boolean;
  menuItemAllowAccess: boolean;
  selectedDocument: KycProof = {};

  applicationAccessSpecifiers: ApplicationAccessSpecifier[];
  menuItemAccessSpecifier: ApplicationAccessSpecifier;
  // this is used to set duplicate of kycProofs response value which we get on api call
  kycProofsPrestine: KycProof[] = [];

  constructor(private formBuilder: FormBuilder,
    private applicationService: ApplicationService,
    private referenceCodeService: ReferenceCodeService,
    private fileService: IgFileService,
    private _snackbar: MatSnackBar,
    private dateAdapter: DateAdapter<Date>,
    private validationService: ValidationService,
    private customDatepipe: DateFormatPipe,
    private imagePreviewDialogService: ImagePreviewDialogService,
    private confirmationDialogService: ConfirmationDialogService,
    private fileUploadService: FileUploadService) {
    this.kycForm = this.formBuilder.group({
      documentProof: '',
      documentNumber: '',
      issuingAuthority: '',
      expiryDate: '',
    });
    this.dateAdapter.setLocale('en-IN');
    this.allowAccess = this.applicationService.allowAccess;
  }

  ngOnInit() {
    this.isFormDisabled = true;
    this.getRefCodes();
    this.fetchKycProofTypes();
    this.fetchKycProofs();
    this.applyValidatorsTokycForm()
    this.getMenuItemAccess();
    
  }

  getRefCodes() {
    this.referenceCodeService.getRefCodesForClassifier('business_proof_doc').subscribe((response: any) => {
      this.businessProofDocumentTypes = response;
      this.referenceCodeService.getShortRefCodes('business_proof_type').subscribe((response: any) => {
        this.businessProofTypes = response.business_proof_type;
        //this.fetchKycProofTypes();
      });
    });
  }

  fetchKycProofs() {
    this.showForm = false;
    this.applicationService.getKycProofs(this.application.uid)
      .subscribe((response: any) => {
        if (response) {
          this.kycProofs = response.body;
          // taking copy of the response in kycProofsPrestine, when click on close we are setting back kycProofsPrestine to kycProofs
          this.kycProofsPrestine = JSON.parse(JSON.stringify(this.kycProofs));
          this.selectedDocument = this.kycProofs[0];
          this.kycProofs.forEach((element, index) => {
            this.kycProofs[index]["deleteBtnDisabled"] = true;
          });
          this.showForm = (this.kycProofs && this.kycProofs.length > 0);
          if (this.kycProofs.length != 0) {
            this.showForm = true;
            this.buildKycForm();
            this.setBusinessProofTypes();
          }

        }
      });
  }

  fetchKycProofTypes() {
    this.applicationService.getKycProofTypes(this.application.uid,
      this.business.uid).subscribe((response: any) => {
        if (response) {
          this.kycProofTypes = response.kycProof;
          this.kycForm.addControl("kycValidityFormGroup",
            this.buildKycValidityFormGroup(this.kycProofTypes));
        }
      });
  }

  buildKycForm(kyc?: KycProof) {
    if (this.showForm) {
      if (!kyc) {
        kyc = this.kycProofs[0];
        this.kycProofs[0].deleteBtnDisabled = false;
      }
      // this.kycForm = this.formBuilder.group({
      this.kycForm.patchValue({
        documentProof: kyc.kycProofDoc,
        documentNumber: kyc.documentNumber,
        issuingAuthority: kyc.issuingAuthority,
        test: kyc.proofValidityNames,
        expiryDate: kyc.documentExpiryDate === null
          || kyc.documentExpiryDate === undefined ?
          undefined : new Date(kyc.documentExpiryDate),
      });
      this.kycForm.controls['expiryDate']
        .setValidators(ControlValidators.minDateValidator(this.application.applicationDate, true, "Expiry Date should be future date as of application date"));
      this.selectedKyc = kyc;
      this.getSelectedFolderFiles(this.selectedKyc.fileFolderUid)
      this.kycForm.disable();
    }
  }

  addNewKYCDocument(kyc) {
    this.showForm = true;
    this.kycProofs.forEach((element, i) => {
      this.kycProofs[i].deleteBtnDisabled = true;
    });
    if (!kyc) kyc = new KycProof();
    this.buildKycForm(kyc);
    this.setBusinessProofTypes();
    this.kycForm.enable();
    this.kycForm.controls.kycValidityFormGroup.disable()
    this.isFormDisabled = false;

  }
  changeSelectedKyc(kyc?: KycProof, index?: number, from?: string) {
    if (!kyc) kyc = new KycProof();
    this.showForm = true;
    this.kycProofs.forEach((element, i) => {
      this.kycProofs[i].deleteBtnDisabled = true;
    });
    if (from != "new") {
      this.kycProofs[index].deleteBtnDisabled = false;
    }
    this.buildKycForm(kyc);
    this.selectedDocument = kyc
    this.setBusinessProofTypes();
  }

  buildKycValidityFormGroup(kycProofTypes: KycProofType[]): FormGroup {
    let kycValidityFormGroup = this.formBuilder.group({});
    let selectedDocument = this.businessProofDocumentTypes.find(
      businessProofDocumentType => businessProofDocumentType.code ===
        this.kycForm.value.documentProof);
    kycProofTypes.forEach(kycProofType => {
      // field1 has kyc validity codes with coma seperated values, hence we need to split
      // based on ',' and we need to mark that matching validity code as selected
      // when we do addNewKYCDocument there won't be any matching code because there won't be any document selected, hence selectedDocument won't be available 
      let isSelected = false;
      if (selectedDocument) {
        let selectedFields = selectedDocument.field1 ? selectedDocument.field1.toLocaleUpperCase().split(',').map(field => field.trim()) : [];
        isSelected = selectedFields.includes(kycProofType.kycProofType.toLocaleUpperCase());
      }

      kycValidityFormGroup.addControl(kycProofType.kycProofType,
        this.formBuilder.control(isSelected));
    });
    this.isKycValidityFormLoaded = true;
    return kycValidityFormGroup;
  }

  setBusinessProofTypes() {
    if(this.kycProofTypes.length !=0 ){
    this.kycForm.removeControl("kycValidityFormGroup");
    this.kycForm.addControl("kycValidityFormGroup",
      this.buildKycValidityFormGroup(this.kycProofTypes));
    }else{
      this.fetchKycProofTypes();
    }
  }

  saveKycProof() {
    this.validationService.markFormGroupTouched(this.kycForm)
    if (!this.isFormDisabled && this.kycForm.valid) {
      let isMatched = this.validationService.validateKycDocumentNumber(this.kycForm.value.documentNumber,this.kycForm.value.documentProof,this.businessProofDocumentTypes)
      if(!isMatched){
            this._snackbar.open("KYC Proof update failed, Invalid document number", "Close", {
              duration: 6000,
            });
            return;
          }

      let kycUid = this.selectedKyc.uid ? this.selectedKyc.uid : "0";
      if (!this.selectedKyc.uid) {
        this.selectedKyc.linkToType = 'entity';
        this.selectedKyc.linkToUid = this.business.uid;
        this.selectedKyc.contextType = 'application';
        this.selectedKyc.contextUid = this.application.uid;
      }
      let kycFormFields = this.kycForm.value;
      this.selectedKyc.kycProofDoc = kycFormFields.documentProof;
      this.selectedKyc.documentNumber = kycFormFields.documentNumber ? kycFormFields.documentNumber.trim() : null;
      this.selectedKyc.issuingAuthority = kycFormFields.issuingAuthority;
      if (this.kycForm.controls.expiryDate.value != undefined && !isNaN(this.kycForm.controls.expiryDate.value.getTime())) {
        if (this.kycForm.controls.expiryDate.valid) {
          this.selectedKyc.documentExpiryDate = this.customDatepipe.transform(this.kycForm.controls.expiryDate.value, 'ISODATE');
        }
      } else {
        this.selectedKyc.documentExpiryDate = null
      }
      this.applicationService.saveKycProof(this.application.uid,
        this.business.uid, kycUid, this.selectedKyc).toPromise().then(
          (_success) => {
            this._snackbar.open("KYC Proof updated successfully", "Close", {
              duration: 2000,
            });
            this.isFormDisabled = true;
            this.fetchKycProofTypes();
            this.fetchKycProofs();
          }, (failure) => {
            let errormesg = []
            errormesg.push(failure)
            this.applicationService.displayErrorMessages(errormesg);
            console.log(failure);
          }
        );
    }
  }

  enableDisableForm() {
    if(this.selectedKyc.verifiedFlag == true){
      this.kycForm.disable();
      this._snackbar.open("KYC already verified. Edit not allowed", "close" ,{
        duration: 5000
      })
      return;
    }
    this.kycForm.enable();
    this.isFormDisabled = false;
    this.kycForm.controls.kycValidityFormGroup.disable()
  }

  closeForm() {
    // we are setting back the kycProofsPrestine value to kycProofs and building the form to get the previous value.
    this.kycProofs = JSON.parse(JSON.stringify(this.kycProofsPrestine));
    this.kycProofs.forEach((element, i) => {
      this.kycProofs[i].deleteBtnDisabled = true;
    });
    if (this.kycProofs && this.kycProofs.length > 0) {
      this.buildKycForm();
      this.setBusinessProofTypes();
    } else {
      this.showForm = false;
    }
    this.kycForm.disable();
    this.isFormDisabled = true;
  }
  //when kycValidityFormGroup is called
  get kycValidityFormGroup(): any {
    return this.kycForm.get('kycValidityFormGroup');
  }

  //this function will apply validators to form group
  applyValidatorsTokycForm() {
    this.validationService.applyValidationRules(this.kycForm, "BusinessKYCproofs").then((controlValidators) => {
    }).catch(() => {
    })

  }
  /* DO NOT DELETE */
  /* PREVIOUSLY USED LOGIC FOR PROOF VALIDITY*/
  /* setBusinessProofTypes() {
    //If previous proof type is empty initialize it to selected proof type
    if(!this.previousKycType) this.previousKycType = this.selectedKycType;

    //Get previous document proof type name
    let previousProofType = this.businessProofDocumentTypes.find(
      businessProofDocumentType => businessProofDocumentType.code === 
        this.previousKycType).name;
    
    //Remove previous document proof name from Kyc validity checkbox list
    this.kycProofTypes.forEach((kycProofType) => {
      kycProofType.kycProofDoc = kycProofType.kycProofDoc.split(",")
      .filter(kycProofDoc => kycProofDoc !== previousProofType).join(",");
    });

    //Update previousProofType to current document proof type
    this.previousKycType = this.kycForm.value.documentProof;
    
    //Get selected document proof ref code object
    let selectedDocument = this.businessProofDocumentTypes.find(
      businessProofDocumentType => businessProofDocumentType.code === 
        this.kycForm.value.documentProof);

    //If selected document proof has field1, update in Kyc validity
    if(selectedDocument.field1 !== null) {
      this.kycProofTypes.map(kycProofType => {
        if(kycProofType.kycProofType === selectedDocument.field1 && 
            !kycProofType.kycProofDoc.includes(selectedDocument.name)) {
          kycProofType.kycProofDoc += kycProofType.kycProofDoc === "" ?
            selectedDocument.name : "," + selectedDocument.name;
        }
        return kycProofType;
      });
    }

    //If selected document proof has field2, update in Kyc validity
    if(selectedDocument.field2 !== null) {
      this.kycProofTypes.map(kycProofType => {
        if(kycProofType.kycProofType === selectedDocument.field2 && 
            !kycProofType.kycProofDoc.includes(selectedDocument.name)) {
          kycProofType.kycProofDoc += kycProofType.kycProofDoc === "" ?
            selectedDocument.name : "," + selectedDocument.name;
        }
        return kycProofType;
      });
    }

    //Reset Kyc validity with new values
    this.kycForm.removeControl("kycValidityFormGroup");
    this.kycForm.addControl("kycValidityFormGroup",
      this.buildKycValidityFormGroup(this.kycProofTypes));
  } */


  //this function will trigger onclicking of image,will open up a mat-dailog
  openContentElement() {
    this.imagePreviewDialogService.open({ "images": this.allFilesInFolder })
  }

  //this function open up the image upload dailog box
  //if file upload should allow single and multiple file upload, we need to pass "uploadType":"multiple"
  openImageFileUploadDialog() {
    if (this.selectedKyc.fileFolderUid) {
      this.fileUploadService.open({ "uploadType": "multiple", "folderUid": this.selectedKyc.fileFolderUid, "applicationUid": this.application.uid, "files": this.allFilesInFolder.fileInfo })
      this.fileUploadService.confirmed().subscribe(data => {
        this.getSelectedFolderFiles(data.folderUid)
      })

    } else {
      this._snackbar.open("Please add a folder to upload files", "Close", {
        duration: 5000,
      });
    }
  }

  getSelectedFolderFiles(folderUid) {
    if (folderUid) {
      this.applicationService.getAllFilesInApplicationFolder(this.application.uid, folderUid).subscribe((response: any) => {
        if (response) {
          this.allFilesInFolder = response.body;
          this.allFilesInFolder.fileInfo.forEach(element => {
            if (element.fileSize) {
              element["convertedFileSize"] = this.applicationService.bytesToSize(element.fileSize)
            }
          });
        }
      });
    }
  }

  //this function will call onclick of download icon in file's section
  downloadFile(file) {
    this.applicationService.downloadFileFromApplicationFolder(this.application.uid, file.folderUid, file.fileId).subscribe(image => {
      const blob = new Blob([image.data], { type: file.type });
      fileSaver.saveAs(blob, file.fileName);
      this._snackbar.open("File has been downloaded successfully", "Close", {
        duration: 5000,
      });
    })
  }

  //this function will get either this menu item  is editable or readonly 
  getMenuItemAccess() {
    this.applicationService.getApplicationAccessModifiers(this.application.uid)
      .subscribe((response) => {
        this.applicationAccessSpecifiers = response.body;
        this.menuItemAccessSpecifier = this.applicationAccessSpecifiers
          .find(accessSpecifier => accessSpecifier.category === this.menuCode);
        if (this.menuItemAccessSpecifier) {
          this.menuItemAllowAccess = this.menuItemAccessSpecifier.allowAccess;
        }
      });
  }

  //this function is called on click of expirydate input
  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    if (!this.isFormDisabled) {
      if (event.value == null) {
        this.kycForm.controls.expiryDate.patchValue(undefined);
      }
    }
  }

  //this function will be called on click of delete icon on Kyc
  deleteKyc(selectedKyc) {
    this.applicationService.kycDelete(selectedKyc.uid).subscribe((response) => {
      this._snackbar.open("KYC Proof deleted successfully", "Close", {
        duration: 2000,
      });
      this.fetchKycProofTypes();
      this.fetchKycProofs();
    }, (failure) => {
      let errormesg = []
      errormesg.push("KYC Proof deleted failed")
      errormesg.push(failure)
      this.applicationService.displayErrorMessages(errormesg);
    });
  }

  openDeleteConfirmation() {
    this.confirmationDialogService.open({ "btnCancelText": "Cancel", "btnConfirmText": "Delete", "message": "Are you sure you want to delete this KYC Proof?" })
    this.confirmationDialogService.confirmed().subscribe(data => {
      if (data) {
        this.deleteKyc(this.selectedKyc)
      }
    })
  }

  //this function will be called on click of delete icon on file list
  deleteFile(selectedFile) {
    this.applicationService.fileDelete(this.application.uid, selectedFile.uid).subscribe((response) => {
      if (response.body.status == "success") {
        this._snackbar.open("File deleted successfully", "Close", {
          duration: 2000,
        });
        this.getSelectedFolderFiles(this.selectedKyc.fileFolderUid);
      } else {
        let errormesg = []
        errormesg.push("File deleted failed")
        errormesg.push(response.body.message)
        this.applicationService.displayErrorMessages(errormesg);
      }
    }, (failure) => {
      let errormesg = []
      errormesg.push("File deleted failed")
      errormesg.push(failure)
      this.applicationService.displayErrorMessages(errormesg);
    });
  }

  openfileDeleteConfirmation(selectedFile) {
    this.confirmationDialogService.open({ "btnCancelText": "Cancel", "btnConfirmText": "Delete", "message": "Are you sure you want to delete this File?" })
    this.confirmationDialogService.confirmed().subscribe(data => {
      if (data) {
        this.deleteFile(selectedFile)
      }
    })

  }
 

}