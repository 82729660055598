import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DateAdapter,MatSnackBar } from '@angular/material';
import { ValidationService } from '../../../application-validators/validation.service';
import { ApplicationService } from '@app/applications/application.service';
import { LoanApplication,ApplicationAccessSpecifier } from '@app/applications/applications.model';
import { PostDateCheques } from './post-date-cheque.model';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DateFormatPipe } from '@app/utils/date-format.pipe';
import { ConfirmationDialogService } from '@app/utils/confirmation-dialog/confirmation-dialog.service';
@Component({
  selector: 'eng-post-dateCheque',
  templateUrl: 'post-date-cheque.template.html',
  styleUrls: ['../../application-details.styles.scss']
})
export class PostDateChequeComponent implements OnInit {

  @Input()
  application: LoanApplication;
  @Input()
    menuCode:string;


  postDateCheques: PostDateCheques[];
  pdcForm: FormGroup;
  selectedpostDateCheque: PostDateCheques;

  isFormDisabled: boolean;
  showForm: boolean;
  allowAccess:Boolean;
  menuItemAllowAccess:boolean;

  applicationAccessSpecifiers: ApplicationAccessSpecifier[];
  menuItemAccessSpecifier: ApplicationAccessSpecifier;

  constructor(private formBuilder: FormBuilder,
    // private referenceCodeService: ReferenceCodeService,
    private customDatepipe: DateFormatPipe,
    private applicationService: ApplicationService,
    private _snackbar: MatSnackBar,
    private dateAdapter: DateAdapter<Date>,
    private validationService: ValidationService,
    private confirmationDialogService: ConfirmationDialogService) {
    this.pdcForm = this.formBuilder.group({
      pdcChequeNo: '',
      pdcChequeDate: '',
      pdcChequeAmount: '',
      pdcPurpose: '',
      pdcMicr: '',
    });
      this.dateAdapter.setLocale('en-IN');
    this.allowAccess = this.applicationService.allowAccess;
  }

  ngOnInit(): void {
    this.isFormDisabled = true;
    // this.getRefCodes();
    this.applyValidatorsToPdc();
    this.getMenuItemAccess();

  }

  // getRefCodes() {
  //   this.referenceCodeService.getParentClassfier('').subscribe((response: any) => {
  //     this. = response.;
  //   });
  // }

  fetchPostlDateCheque() {
    this.showForm = false;
    this.applicationService.getPostDateCheque(
      this.application.uid).subscribe((response: any) => {
        if (response) {
          this.postDateCheques = response.body;
          this.postDateCheques.forEach((element, index) => {
            this.postDateCheques[index]["deleteBtnDisabled"] = true;
          });
          this.showForm = (this.postDateCheques && this.postDateCheques.length > 0);
          this.buildPdcForm();
        }
      });
  }

  addNewPdc() {
    this.showForm = true;
    this.postDateCheques.forEach((element,i) => {
      this.postDateCheques[i].deleteBtnDisabled = true;
    });
    this.buildPdcForm(new PostDateCheques());
    this.isFormDisabled = false;
  }

  changeSelectedPdc(postDateCheque?: PostDateCheques, index?: number) {
    this.showForm = true;
    this.postDateCheques.forEach((element, i) => {
      this.postDateCheques[i].deleteBtnDisabled = true;
    });
    this.postDateCheques[index].deleteBtnDisabled = false;
    this.buildPdcForm(postDateCheque);
  }

  buildPdcForm(pdc?: PostDateCheques) {
    if (this.showForm) {
      if (!pdc) {
        pdc = this.postDateCheques[0];
        this.postDateCheques[0].deleteBtnDisabled = false;
      }
      this.pdcForm.patchValue({
        pdcChequeNo: pdc.referenceNumber,
        pdcChequeDate: pdc.chequeDate === null || pdc.chequeDate === undefined ? undefined : new Date(pdc.chequeDate),
        pdcChequeAmount: pdc.amount,
        pdcPurpose: pdc.purpose,
        pdcMicr: pdc.micr,
      });
      this.selectedpostDateCheque = pdc;
    }
  }

 
  savePdc() {
    this.validationService.markFormGroupTouched(this.pdcForm)
    if (!this.isFormDisabled && this.pdcForm.valid) {
      let pdcFormFields = this.pdcForm.value;
      this.selectedpostDateCheque.referenceNumber = pdcFormFields.pdcChequeNo;
      this.selectedpostDateCheque.chequeDate = pdcFormFields.pdcChequeDate === null || pdcFormFields.pdcChequeDate === undefined ? undefined : pdcFormFields.pdcChequeDate.getTime();
      this.selectedpostDateCheque.amount = pdcFormFields.pdcChequeAmount;
      this.selectedpostDateCheque.purpose = pdcFormFields.pdcPurpose;
      this.selectedpostDateCheque.micr = pdcFormFields.pdcMicr;
      if(!this.selectedpostDateCheque.micr) {
        this.selectedpostDateCheque.micr = null;
      }
      this.applicationService.savePdcComponent(this.application.uid,
        this.selectedpostDateCheque.uid, this.selectedpostDateCheque).toPromise().then((_success) => {
          this._snackbar.open("Post Date Cheque updated successfully", "Close", {
            duration: 2000,
          });
          this.isFormDisabled = true;
          this.fetchPostlDateCheque();
        }, (failure) => {
          let errormesg =[]
          errormesg.push("Post Date Cheque update failed")
          errormesg.push(failure)
          this.applicationService.displayErrorMessages(errormesg);
        }
        );
    }
  }

  enableDisableForm() {
    this.isFormDisabled = false;
  }

  cancelForm() {
    this.postDateCheques.forEach((element, i) => {
      this.postDateCheques[i].deleteBtnDisabled = true;
    });
    if (this.postDateCheques && this.postDateCheques.length > 0) {
      this.buildPdcForm();
    } else {
      this.showForm = false;
    }
    this.isFormDisabled = true;
  }

  //this function will apply validators to form group
  applyValidatorsToPdc() {
    this.validationService.applyValidationRules(this.pdcForm, "PostDateCheque").then((controlValidators) => {
      this.fetchPostlDateCheque();
    }).catch(() => {
    })
  }


  //this function will get either this menu item  is editable or readonly 
  getMenuItemAccess(){
    this.applicationService.getApplicationAccessModifiers(this.application.uid)
        .subscribe((response) => {
          this.applicationAccessSpecifiers = response.body;
          this.menuItemAccessSpecifier = this.applicationAccessSpecifiers
            .find(accessSpecifier => accessSpecifier.category === this.menuCode);
          if(this.menuItemAccessSpecifier){
          this.menuItemAllowAccess = this.menuItemAccessSpecifier.allowAccess;
            }
      });
    }

 //this function is called on click of checkDate input
  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    if (!this.isFormDisabled) {
      if (event.value == null) {
        this.pdcForm.controls.pdcChequeDate.patchValue(undefined);
      }
    }
  }

  openDeleteConfirmation() {
    this.confirmationDialogService.open({ "btnCancelText": "Cancel", "btnConfirmText": "Delete", "message": "Are you sure you want to delete this PDC?" })
    this.confirmationDialogService.confirmed().subscribe(data => {
      if (data) {
        this.deletePdc(this.selectedpostDateCheque)
      }
    })
  }

    //this function will be called on click of delete icon on Kyc
    deletePdc(selectedpostDateCheque) {
      this.applicationService.pdcDelete(this.application.uid,selectedpostDateCheque.uid).subscribe((response) => {
        if(response.body.status == "SUCCESS") {
        this._snackbar.open(response.body.message , "Close", {
          duration: 5000,
        });
        this.fetchPostlDateCheque()
      } else {
        let errormesg = []
        errormesg.push("Post dated cheque delete failed")
        errormesg.push(response.body.message)
        this.applicationService.displayErrorMessages(errormesg);
      }}, (failure) => {
        let errormesg = []
        errormesg.push("Post dated cheque delete failed")
        errormesg.push(failure)
        this.applicationService.displayErrorMessages(errormesg);
      });
    }


}