import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReferenceCodeService } from '@app/admin/reference-code/reference-code.service';
import { LoanApplication, Person,ApplicationAccessSpecifier } from '@app/applications/applications.model';
import { ApplicationService } from '@app/applications/application.service';
import { NameValueDto } from '@ig-core/interfaces/name-value-dto';
import { BankAccounts} from '../../../applications.model'
import { MatSnackBar } from '@angular/material';
import { ValidationService } from '../../../application-validators/validation.service';
import { ConfirmationDialogService } from '@app/utils/confirmation-dialog/confirmation-dialog.service';
import { BankAccountVerificationResponseComposite } from '../../business/businessBankAccount/business-bank-accounts.model';
import { SOURCE_DIGITAL, SOURCE_MANUAL } from '@app/constants/data.constants';
@Component({
  selector: 'eng-applicant-bank-accounts',
  templateUrl: 'applicant-bank-accounts.template.html',
  styleUrls: ['../../application-details.styles.scss']
})
export class ApplicantBankAccountsComponent implements OnInit, OnChanges {

  @Input()
  application: LoanApplication;

  @Input()
  applicant: Person;

  @Input()
  menuCode:string;

  applicantBankAccounts: BankAccounts[];
  applicantBankAccountsForm: FormGroup;
  selectedAccount: BankAccounts;
  accountTypes: NameValueDto[];

  isFormDisabled : boolean;
  showForm: boolean;
  allowAccess:Boolean;
  menuItemAllowAccess:boolean;

  applicationAccessSpecifiers: ApplicationAccessSpecifier[];
  menuItemAccessSpecifier: ApplicationAccessSpecifier;
  bankAccountVerificationResponse: BankAccountVerificationResponseComposite
  // this is used to set duplicate of applicantBankAccounts response value which we get on api call
  applicantBankAccountsPrestine: BankAccounts[] = [];

  constructor(private formBuilder: FormBuilder,
    private referenceCodeService: ReferenceCodeService,
    private applicationService: ApplicationService,
    private _snackbar: MatSnackBar,
    private confirmationDialogService:ConfirmationDialogService,
    private validationService: ValidationService) {
      this.applicantBankAccountsForm = this.formBuilder.group({
        accountType:'',
        accountNumber:'',
        accountHolderName: '',
        branchName: '', 
        bankName: '',
        ifsc: '', 
        bankingSince: '',
        maskedAccountNumber:'',
      });
      this.allowAccess = this.applicationService.allowAccess;
  }

  ngOnInit() {
    this.getRefCodes();
    this.applyValidatorsToApplicantBankAccount();
    this.getMenuItemAccess();

  }

  ngOnChanges(changes: SimpleChanges) {
    this.isFormDisabled = true;
    if(changes.applicant){
      this.fetchApplicantBankAccounts();
    }
  }
  
  getRefCodes() {
    this.referenceCodeService.getShortRefCodes('person_bankac_type').subscribe((response: any) => {
      this.accountTypes = response.person_bankac_type;
    });
  }

  /*
   Applicant Bank Account component, on loading executes this method to retrieve the Applicant Bank Accounts from server by passing Application UID and Applicant UID,
    Based on server response, 'buildApplicantBankAccountForm' function will be executed to update form input values.
  */

  fetchApplicantBankAccounts() {
    this.showForm = false;
    this.applicationService.getApplicantBankAccounts(this.application.uid, this.applicant.uid)
      .subscribe((response: any) => {
        if (response) {
          this.applicantBankAccounts = response.body;
          // taking copy of the response in applicantBankAccountsPrestine, when click on close we are setting back applicantBankAccountsPrestine to applicantBankAccounts
          this.applicantBankAccountsPrestine = JSON.parse(JSON.stringify(this.applicantBankAccounts));
          this.applicantBankAccounts.forEach((element,index) => {
            this.applicantBankAccounts[index]["deleteBtnDisabled"]=true
          });
          this.showForm = (this.applicantBankAccounts && this.applicantBankAccounts.length > 0);
          this.buildApplicantBankAccountForm();
        }
      });
  }

  /*
   Applicant Bank Account component, on click of '+' button this method will be executed  to show empty form,
  */

  addNewBankAccount() {
      this.showForm = true;
      this.isFormDisabled = false;
      this.changeSelectedAccount(new BankAccounts(),undefined,"new");
  }

   /*
   Applicant Bank Account component, on change of radio button selection this method will be executed  to show selected record details in form,
   Delete button is now enabled. This is because, 'false' is used in Angular [disabled] attribute. 
  */
  changeSelectedAccount(applicantBankAccount ?: BankAccounts,index?:number,from?:string) {
    this.showForm = true;
    this.applicantBankAccounts.forEach((element,index) => {
      this.applicantBankAccounts[index]["deleteBtnDisabled"]=true
    });
    if(from != "new"){
      this.applicantBankAccounts[index].deleteBtnDisabled =false
      }
     
    this.buildApplicantBankAccountForm(applicantBankAccount);
  }


  buildApplicantBankAccountForm(bankAccount ?: BankAccounts) {
    this.bankAccountVerificationResponse = null
    this.applicantBankAccountsForm.controls.ifsc.enable();
    this.applicantBankAccountsForm.controls.bankName.enable();
    if(this.showForm) {
      if(!bankAccount) {
        bankAccount = this.applicantBankAccounts[0];
        this.applicantBankAccounts[0].deleteBtnDisabled =false
      }
      this.applicantBankAccountsForm.patchValue({
        accountType:bankAccount.accountType,
        accountNumber: bankAccount.accountNumber,
        accountHolderName: bankAccount.accountHolderName,
        branchName: bankAccount.branchName, 
        bankName: bankAccount.bankName,
        ifsc: bankAccount.ifsc, 
        bankingSince: bankAccount.bankingSince,
        maskedAccountNumber: bankAccount.maskedAccountNumber
      });
      this.selectedAccount = bankAccount;
      // we are calling getBankAccountVerificationEvidence only if the selected document is verified,
      // and if it has serviceProvidedData then only we are displaying the electronic verification reference card
      if(this.selectedAccount.verifiedFlag){
        this.applicationService.getBankAccountVerificationEvidence(this.application.uid,this.selectedAccount.uid).subscribe(response =>{
          if(response.body.serviceProviderData){
          this.bankAccountVerificationResponse = response.body
          }
        })
      }
    }
    if(this.selectedAccount.source === SOURCE_DIGITAL){
      this.applicantBankAccountsForm.controls.ifsc.disable();
      this.applicantBankAccountsForm.controls.bankName.disable();
    }
    this.applicantBankAccountsForm.controls.maskedAccountNumber.disable();
  }
  private markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }
  saveApplicantBankAccount() {
    this.markFormGroupTouched(this.applicantBankAccountsForm)
    if(!this.isFormDisabled && this.applicantBankAccountsForm.valid){
      if(!this.selectedAccount.uid) {
        this.selectedAccount.linkToType = 'person';
        this.selectedAccount.linkToUid = this.applicant.uid;
        this.selectedAccount.linkedAs = 'business';
        this.selectedAccount.contextType = 'application';
        this.selectedAccount.contextUid = this.applicant.uid;
      }
      let applicantBankAccountFormFields = this.applicantBankAccountsForm.value;
      this.selectedAccount.accountType = applicantBankAccountFormFields.accountType;
      this.selectedAccount.accountNumber = applicantBankAccountFormFields.accountNumber;
      this.selectedAccount.accountHolderName = applicantBankAccountFormFields.accountHolderName;
      this.selectedAccount.branchName = applicantBankAccountFormFields.branchName;
      this.selectedAccount.bankName = this.applicantBankAccountsForm.getRawValue().bankName;
      this.selectedAccount.ifsc = this.applicantBankAccountsForm.getRawValue().ifsc;
      this.selectedAccount.bankingSince = applicantBankAccountFormFields.bankingSince;
      this.selectedAccount.source = this.selectedAccount.source ? this.selectedAccount.source : SOURCE_MANUAL;
      this.applicationService.saveApplicantBankAccount(this.application.uid, this.applicant.uid,
         this.selectedAccount).toPromise().then(
           (_success) => {
             this._snackbar.open("Bank account updated successfully", "Close", {
               duration: 2000,
             });
             this.isFormDisabled = true;
             this.fetchApplicantBankAccounts();
             
           },(failure) => {
            let errormesg =[]
            errormesg.push(failure)
            this.applicationService.displayErrorMessages(errormesg);
            
           }
         )
    }
  }

  enableDisableForm() {
    //if verifiedFlag is true then we are not allowing user to edit verified bankAccount details,hence disabling the fields
     if(this.selectedAccount.verifiedFlag == true){
      this.isFormDisabled = true;
      this._snackbar.open("Bank account already verified. Edit not allowed", "close" ,{
        duration: 5000
      })
      return;
    }
    this.isFormDisabled = false;
  }
  
  cancelForm() {
    // we are setting back the applicantBankAccountsPrestine value to applicantBankAccounts and building the form to get the previous value.
    this.applicantBankAccounts = JSON.parse(JSON.stringify(this.applicantBankAccountsPrestine));
    this.applicantBankAccounts.forEach((element, i) => {
      this.applicantBankAccounts[i].deleteBtnDisabled = true;
    });
    if(this.applicantBankAccounts && this.applicantBankAccounts.length > 0) {
      this.buildApplicantBankAccountForm();
    } else {
      this.showForm = false;
    }
    this.isFormDisabled = true;
  }
   //this function will apply validators to form group
   applyValidatorsToApplicantBankAccount() {
    this.validationService.applyValidationRules(this.applicantBankAccountsForm,"ApplicantBankAccount").then((controlValidators) => {
     
    }).catch(() => {
    })
  }

  //this function will get either this menu item  is editable or readonly 
  getMenuItemAccess(){
    this.applicationService.getApplicationAccessModifiers(this.application.uid)
        .subscribe((response) => {
          this.applicationAccessSpecifiers = response.body;
          this.menuItemAccessSpecifier = this.applicationAccessSpecifiers
            .find(accessSpecifier => accessSpecifier.category === this.menuCode);
          if(this.menuItemAccessSpecifier){
          this.menuItemAllowAccess = this.menuItemAccessSpecifier.allowAccess;
            }
      });
    }

        //this function will be called on click of delete icon on collateral list
deleteBankAccount(selectedBankAccount){
  this.applicationService.bankAccountDelete(this.application.uid, selectedBankAccount.uid).subscribe((response) => {
    if(response.body.status == "success") {
    this._snackbar.open("Bank account deleted successfully", "Close", {
      duration: 2000,
    });
    this.fetchApplicantBankAccounts();
  } else {
    let errormesg =[]
    errormesg.push("Bank account deleted failed")
    errormesg.push(response.body.message)
    this.applicationService.displayErrorMessages(errormesg);
  }}, (failure) => {
    let errormesg =[]
    errormesg.push("Bank account deleted failed")
    errormesg.push(failure)
    this.applicationService.displayErrorMessages(errormesg);
  });
}

openDeleteConfirmation(){
  this.confirmationDialogService.open({ "btnCancelText":"Cancel","btnConfirmText":"Delete","message":"Are you sure you want to delete this Bank acccount?"})
  this.confirmationDialogService.confirmed().subscribe(data=>{
    if(data){
    this.deleteBankAccount(this.selectedAccount)
    }
  })
}
}

