import { Component, OnInit, Input } from '@angular/core';
import { LoanApplication, Person, ApplicationAccessSpecifier } from '@app/applications/applications.model';
import { ApplicationService } from '@app/applications/application.service';


@Component({
  selector: 'eng-at-a-glance-card',
  templateUrl: './at-a-glance.component.html',
  styleUrls: ['../../application-details.styles.scss', './at-a-glance.component.scss']
})
export class AtAGlanceComponent implements OnInit {
  @Input() application: LoanApplication;
  @Input() menuCode: string;

  allowAccess: Boolean;
  menuItemAllowAccess: boolean;

  applicationAccessSpecifiers: ApplicationAccessSpecifier[];
  menuItemAccessSpecifier: ApplicationAccessSpecifier;


  engrowAtGlanceComposit: any;
  engrowAtGlanceCompositPristine: any;
  constructor(private applicationService: ApplicationService) {
    this.allowAccess = this.applicationService.allowAccess;
  }

  ngOnInit() {
    this.getMenuItemAccess();
    this.getEngrowAtAGlanceDetails()
  }

  //this function will get either this menu item  is editable or readonly 
  getMenuItemAccess() {
    this.applicationService.getApplicationAccessModifiers(this.application.uid)
      .subscribe((response) => {
        this.applicationAccessSpecifiers = response.body;
        this.menuItemAccessSpecifier = this.applicationAccessSpecifiers
          .find(accessSpecifier => accessSpecifier.category === this.menuCode);
        if (this.menuItemAccessSpecifier) {
          this.menuItemAllowAccess = this.menuItemAccessSpecifier.allowAccess;
        }
      });
  }


  getEngrowAtAGlanceDetails() {
    this.applicationService.getEngrowAtGlance(this.application.uid).subscribe(res=>{
        this.engrowAtGlanceComposit = res.body
        this.engrowAtGlanceCompositPristine = JSON.parse(JSON.stringify(this.engrowAtGlanceComposit));
      })
  }

  onSearchCriteria(searchValue: string) {
    searchValue = searchValue.trim().toLowerCase();
    this.engrowAtGlanceComposit = JSON.parse(JSON.stringify(this.engrowAtGlanceCompositPristine));

    this.engrowAtGlanceComposit = this.engrowAtGlanceComposit.filter(criteria => {
        criteria.data = criteria.data.filter(ele => {
            ele.data = ele.data.filter(element =>
                element.classification1.toLowerCase().includes(searchValue) ||
                element.classification2.toLowerCase().includes(searchValue) ||
                element.message.toLowerCase().includes(searchValue)
            );

            return ele.data.length > 0;
        });

        return criteria.data.length > 0;
    });
}

}
