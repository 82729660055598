import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ReportLink } from './reports.model';
import { ReportService } from './reports.service';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { MenuItem } from 'projects/multi-level-menu/src/lib/menu-item';
import { DateAdapter } from '@angular/material';

@Component({
  selector: 'eng-reports',
  templateUrl: 'reports.template.html',
  styleUrls: ['reports.styles.scss',
    '../applications/application-details/application-details.styles.scss']
})
export class ReportsComponent implements OnInit, OnDestroy {

  reportLinks: ReportLink[];
  contextUid: string;
  category: string;
  searchText: string;
  filteredList: ReportLink[];

  // this variable is used to decide which enable button to show for next routing 
  showDocumentOrReportButton: boolean;
  
  constructor(private activatedRoute: ActivatedRoute,
      private reportService: ReportService,
      private dateAdapter: DateAdapter<Date>) { this.dateAdapter.setLocale('en-IN') }

  ngOnInit() {
    this.showDocumentOrReportButton = true;
    this.getReportLinks();
  }

  getReportLinks() {
    this.activatedRoute.queryParams
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((params) => {
        this.contextUid = params.contextUid;
        this.category = params.category
    });

    // we are using report component for generating both document and reports.We are differentiating
    // reports and documents based on category. ON click of documentation in the menu, category is passed as document.
    //If category is not set, it is assumed that the cateogry is report.

    if(this.category == null){
    this.category = "report";
    this.showDocumentOrReportButton = false;
    }
    this.reportService.getReportLinks(this.category).subscribe((response) => {
      this.reportLinks = response.body;
      this.filteredList = this.reportLinks;
      this.reportLinks.forEach(reportLink => {
        reportLink.contextType = 'application';
        reportLink.contextUid = this.contextUid;
      });
    });
  }

  onSearchCriteria(searchValue: string){
    searchValue = searchValue.trim();
    this.filteredList = this.reportLinks.filter(reportLink =>
      reportLink.reportName.toLowerCase().includes(searchValue.toLowerCase())
    )
  }

  ngOnDestroy(): void {
  }
}