import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApplicationService } from '@app/applications/application.service';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { OnDestroy } from '@angular/core';
import { LoanDocumentDTO } from '@app/applications/application-details/loanInformation/loan-document/loan-document.model';
@Component({
  selector: 'eng-esign',
  templateUrl: 'e-sing.template.html',
  styleUrls: ['../../../application-details.styles.scss', '../xdata-service.style.scss']
})
export class EsignComponent implements OnInit, OnDestroy {

  applicationUid: string;

  esignRequestForm: FormGroup;
  loanDocumets: LoanDocumentDTO[] = [];
  showReportHighlight: boolean = false;
  esignResponse: any;
  serviceProviderCode: string;


  constructor(private formBuilder: FormBuilder,
    private applicationService: ApplicationService,
    private _snackbar: MatSnackBar,
    private activatedRoute: ActivatedRoute) {
    this.esignRequestForm = this.formBuilder.group({
      loanDocument: ['', Validators.required]
    });
    this.activatedRoute.queryParams
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((params) => {
        this.applicationUid = params.applicationUid;
        this.serviceProviderCode = params.serviceType;
    });
  }

  ngOnInit() {
    this.onXDataServiceSelect();
  }

  onXDataServiceSelect(){
    this.serviceProviderCode = this.serviceProviderCode.toLowerCase();
    this.applicationService.getLoanDocument(this.applicationUid,this.serviceProviderCode).subscribe(respose => {
      this.loanDocumets = respose.body;

      if (this.loanDocumets.length === 0 ) {
        this._snackbar.open(
          "Loan document not available for " + this.serviceProviderCode , "close",
          {
            duration: 5000,
          }
        );
        return;
      }
  
      // if there is only one loanDocumets is captured then we are pre-populating in the dropdown,
      // if there are more than one recored then user need to select from the dropdown 
      if (this.loanDocumets.length === 1 ) {
        this.esignRequestForm.patchValue({
          loanDocument: this.loanDocumets[0]
        });
      }
    })
  }

  onInitiateClick(){
    if(this.esignRequestForm.valid){
      let loanDocumentUid = this.esignRequestForm.controls.loanDocument.value.uid;
    this.applicationService.initiateEsign(this.applicationUid, loanDocumentUid).toPromise().then(
      (_success) => {
      this.esignResponse = _success;
        this.showReportHighlight = true;
      this._snackbar.open("Complete esign and download document for signed document", "Close", {
        duration: 8000,
      })
    },(failure) => {
      this._snackbar.open(failure , "close", {
        duration: 8000,
      })
  })
    }
  }
  ngOnDestroy(): void {
  }
}
