import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator, MatSnackBar, MatSort, DateAdapter } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ListSource } from '../../../../projects/ig-core/src/lib/list/list-source';
import { PagingAndSortParams } from '../../../../projects/ig-core/src/lib/list/paging-and-sort-params';
import { OrganizationStructureDataSource } from './organization-structure.data.source';
import { OrganizationStructureService } from './organization-struture.service'
import { OrgStructureSearchCriteria, OrganizationStructure, OrgUnit } from './organization-structure.model'
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { ReferenceCodeService } from '../reference-code/reference-code.service';
import { NameValueDto } from '@ig-core/interfaces/name-value-dto';
import { forEach } from '@angular/router/src/utils/collection';

@Component({
  selector: 'eng-organization-structure',
  templateUrl: './organization-structure.template.html',
  styleUrls: ['./organization-structure.component.scss']
})

export class OrganizationStructureComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(MatPaginator)
  paginator: MatPaginator;

  @ViewChild(MatSort)
  sort: MatSort;

  pagingAndSortParams: PagingAndSortParams;

  dataSource: OrganizationStructureDataSource;
  listSource: ListSource;

  orgStructureSearchForm: FormGroup;
  searchCriteria: OrgStructureSearchCriteria;

  flexMediaWatcher: Subscription;
  currentScreenWidth: string = '';
  displayedColumns: string[] = ['chain', 'level', 'name', 'parentOrgUnitName', 'branchCode', 'action'];

  orgChains: NameValueDto[] = [];
  chainLevels: OrgUnit[] = [];
  allLevels: OrgUnit[] = [];

  constructor(private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private media: MediaObserver,
    private organizationStructureService: OrganizationStructureService,
    private referenceCodeService: ReferenceCodeService,
    private dateAdapter: DateAdapter<Date>) {
    this.flexMediaWatcher = media.media$.subscribe((change: MediaChange) => {
      if (change.mqAlias !== this.currentScreenWidth) {
        this.currentScreenWidth = change.mqAlias;
        this.setTableColumns();
      }
    });
    this.dateAdapter.setLocale('en-IN');
  }

  setTableColumns() {
    this.displayedColumns = ['chain', 'level', 'name', 'parentOrgUnitName', 'branchCode', 'action'];
    if (this.currentScreenWidth === 'xs') {
      this.displayedColumns = ['chain', 'level', 'action'];
    }
  }

  ngOnInit() {
    this.getReferenceCodes();
    // fetch all levels
    this.fetchLevels();
    //Instantiate default "business" search form
    this.orgStructureSearchForm = this.formBuilder.group({
      level: "",
      chain: "BIZ"
    });
    // fetch levels for chain=BIZ (default)
    this.fetchLevels("BIZ");


    //Instantiate dataSource for subscribing changes in list data
    this.dataSource = new OrganizationStructureDataSource(this.organizationStructureService);

    //Instantiate listSource for subscribing changes in search criteria
    this.listSource = new ListSource(this.activatedRoute, this.paginator, this.sort);

    //Subscribe to changes in search criteria
    this.listSource.getListParams().pipe(takeUntil(componentDestroyed(this)))
      .subscribe((listParams) => {
        this.pagingAndSortParams = listParams.pagingAndSortParam;
        this.getOrganizationStructure();
      });

  }

  ngOnDestroy(): void {
    this.listSource.disconnect();
  }

  getQueryParams() {
    const queryParams = this.listSource.getQueryParamWithPaginationAndSortParams();
    return queryParams;
  }

  loadApplicationPageWithParams() {
    this.router.navigate([], this.getQueryParams());
  }

  ngAfterViewInit(): void {
    //Subscribe to changes in pagination and sort criteria
    this.listSource.subscribePaginationAndSortChange()
      .pipe(takeUntil(componentDestroyed(this))).subscribe(
        () => this.loadApplicationPageWithParams());
  }

  generateOrganizationStructureSearchCriteria() {
    this.searchCriteria = {
      level: this.orgStructureSearchForm.value.level,
      chain: this.orgStructureSearchForm.value.chain
    }
  }

  getOrganizationStructure(orgSearch?: string) {
    // When the user clicks on the search, we reset the page index to zero.
    // This is necessary because if the user is on a different page and the search result
    // include only one page data, they won't see in the search results
    if(orgSearch === 'onClickOrgStructureSearch'){
      this.pagingAndSortParams.pageIndex = 0;
    }
    this.generateOrganizationStructureSearchCriteria();
    //Sorting on uid in ascending order by default 
    let sortCriteria = 'uid' + ',' + 'asc';
    //Subscribe to changes in list data
    this.dataSource.getOrganizationStructure(sortCriteria, this.pagingAndSortParams.pageIndex,
      this.pagingAndSortParams.pageSize, this.searchCriteria);
  }

  getReferenceCodes() {
    this.referenceCodeService.getShortRefCodes('org_chain').subscribe((response: any) => {
      this.orgChains = response.org_chain;
      // for (let i=0; i < this.orgChains.length; i++) {
      //   this.chainMap.set(this.orgChains[i].code, this.orgChains[i].chain)
      // }
    });

    // this.referenceCodeService.getParentClassfier('org_chain').subscribe((response: any) => {
    //   this.orgChains = response.org_chain;
    // });
  }


  fetchLevels(chain ?: string) {
    this.organizationStructureService.getLevels(chain).subscribe((response: any) => {
      if (response) {
        if (!chain && chain !== '') {
          this.allLevels = response.body;
        } else {
          this.chainLevels = response.body;
          // When the 'chain' value changes, we need to reset the 'level' value in the form
          // This is because different chains have different levels, and the previous 'level' value
          // might not be valid for the new chain. To avoid using an invalid level, we reset it.
          this.orgStructureSearchForm.patchValue({
            level: "",
            chain: this.orgStructureSearchForm.value.chain
          });
        }
      }
    });
  }

}