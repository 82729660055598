import { Component, OnInit, Input } from '@angular/core';
import { LoanApplication,ApplicationAccessSpecifier } from '@app/applications/applications.model';
import { ColletralDetails, Applicant, Collateral } from '../collateralDetails/collateral-details.model';
import { NameValueDto } from '@ig-core/interfaces/name-value-dto';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ReferenceCodeService } from '@app/admin/reference-code/reference-code.service';
import { ApplicationService } from '@app/applications/application.service';
import { ValidationService } from '@app/applications/application-validators/validation.service';
import { MatSnackBar } from '@angular/material';
import { FileUploadService } from '@app/utils/file-upload/file-upload.service';
import { File, Folder, FolderFilesComposite } from '@app/applications/application-details/loanInformation/loan-folder-files/loan-folder-files.model';
import * as fileSaver from 'file-saver';
import { ConfirmationDialogService } from '@app/utils/confirmation-dialog/confirmation-dialog.service';
import { ImagePreviewDialogService } from '@app/utils/image-preview-dialog/image-preview-dialog.service';
@Component({
  selector: 'eng-collateral-verification',
  templateUrl: 'collateral-verification.template.html',
  styleUrls: ['../../application-details.styles.scss', './collateral-verification.style.css']
})
export class ColletralVerificationComponent implements OnInit {

  @Input()
  application: LoanApplication;
  @Input()
    menuCode:string;

  applicants: Applicant[];

  colletralDetails: ColletralDetails[];

  collateral: Collateral;
  selectedCollateral: ColletralDetails;

  collatrelDetailsForm: FormGroup;

  collateralApplicantsList: NameValueDto[] = [];
  collateralTypes: NameValueDto[];
  collateralUses: NameValueDto[];
  applicationAccessSpecifiers: ApplicationAccessSpecifier[];
  menuItemAccessSpecifier: ApplicationAccessSpecifier;
  allFilesInFolder: FolderFilesComposite;

  showForm: boolean;
  isFormDisabled: boolean;
  allowAccess:boolean; 
  menuItemAllowAccess:boolean;
  isCollateralFinalized:boolean;

  constructor(private formBuilder: FormBuilder,
    private applicationService: ApplicationService,
    private _snackbar: MatSnackBar,
    private referenceCodeService: ReferenceCodeService,
    private fileUploadService: FileUploadService,
    private confirmationDialogService:ConfirmationDialogService,
    private imagePreviewDialogService: ImagePreviewDialogService,
    private validationService: ValidationService) {
    this.collatrelDetailsForm = this.formBuilder.group({
      collateralType: '',
      collateralUse: '',
      agriProperty:'',
      assetValue: '',
      legalRemarks:'',
      advocateName:'',
      techRemarks:'',
      valuerName:''
    });
    this.allowAccess = this.applicationService.allowAccess; 
  }

  ngOnInit() {
    this.refreshApplicationData()
    this.fetchApplicants();
    
   this.getCollateralStatus();
    this.getMenuItemAccess();
   
  }

  getRefCodes() {
     //it is possible that we may restrict that -prop must be present 
    let collateralSet = this.application.collateralSet
    this.referenceCodeService.getRefCodesForClassifier(collateralSet).subscribe((response: any) => {
      this.collateralTypes = response;
      this.fetchCollateralDetails();
    });
  
    this.referenceCodeService.getShortRefCodes('collateral_usedfor').subscribe((response: any) => {
      this.collateralUses = response.collateral_usedfor;
    });
  }

  fetchCollateralDetails() {
   
    this.showForm = false;
    this.applicationService.getCollateralDetails(this.application.uid).subscribe((response: any) => {
      if (response) {
        this.colletralDetails = response;
        this.colletralDetails.forEach((element, index) => {
          let CheckType=this.collateralTypes.filter(object=>{return object.code ==element.collateral.collateralType })
          if(CheckType.length ==0 && this.collateralTypes.length !=0 ){
            this.colletralDetails[index]["showInRed"] = true
          }
        });
        this.isFormDisabled = true;
        this.showForm = (this.colletralDetails && this.colletralDetails.length > 0);
        this.buildCollateralVerification();
      }
    })
  }

  fetchApplicants() {
    this.showForm = false;
    this.applicationService.getApplicants(this.application.uid).subscribe((response: any) => {
      if (response) {
        this.applicants = response;
        this.collateralApplicants();
      }
    })
  }

  collateralApplicants() {
    this.applicants.forEach((applicantsObject) => {
      let applicant = { code: applicantsObject.uid, name: applicantsObject.name + '  ' + '(' + applicantsObject.play + ')' }
      this.collateralApplicantsList.push(<NameValueDto>applicant);
    })
  }

  changeSelectedCollateral(collateraldetails?: ColletralDetails) {
    this.showForm = true;
    this.buildCollateralVerification(collateraldetails)
  }
 
  //this function will patch the server values to formgroup formcontrols
  buildCollateralVerification(collaterals?: ColletralDetails) {
    if (this.showForm) {
      if (!collaterals) collaterals = this.colletralDetails[0];
      this.collatrelDetailsForm.patchValue({
        agriProperty:collaterals.collateral.agriProperty,
        collateralType: collaterals.collateral.collateralType,
        collateralUse: collaterals.collateral.collateralUse,
        assetValue:collaterals.collateral.evalMarketValue,
        advocateName:collaterals.collateral.advocateName,
        legalRemarks:collaterals.collateral.legalRemarks,
        techRemarks:collaterals.collateral.techRemarks,
        valuerName:collaterals.collateral.valuerName,
      });
      this.selectedCollateral = collaterals;
      this.getSelectedFolderFiles(this.selectedCollateral.collateral.fileFolderUid);
    }
  }


//this function will be called for saving and updating a collateral details
  saveCollateralVerification() {
    this.validationService.markFormGroupTouched(this.collatrelDetailsForm)
    //checking is the form group is valid or not
      if (!this.isFormDisabled && this.collatrelDetailsForm.valid) {
        let collatrelVerificationFormFields = this.collatrelDetailsForm.controls;
        this.selectedCollateral.collateral.advocateName = collatrelVerificationFormFields.advocateName.value;
        this.selectedCollateral.collateral.techRemarks = collatrelVerificationFormFields.techRemarks.value;
        this.selectedCollateral.collateral.legalRemarks = collatrelVerificationFormFields.legalRemarks.value;
        this.selectedCollateral.collateral.evalMarketValue = collatrelVerificationFormFields.assetValue.value;
        this.selectedCollateral.collateral.agriProperty = collatrelVerificationFormFields.agriProperty.value;
        this.selectedCollateral.collateral.valuerName = collatrelVerificationFormFields.valuerName.value;
        
        this.applicationService.saveCollateralDetails(this.application.uid, this.selectedCollateral.collateral.uid, this.selectedCollateral).toPromise().then(
                (_success) => {
                  this._snackbar.open("Collateral updated successfully", "Close", {
                    duration: 2000,
                  });
                  this.fetchCollateralDetails();
                }, (failure) => {
                  let errorMessage =[]
                  errorMessage.push(failure)
                  this.applicationService.displayErrorMessages(errorMessage);
                }
              );
            }
          }

  enableDisableForm() {
    this.isFormDisabled = false;
    if(this.isCollateralFinalized){
      this.collatrelDetailsForm.controls.assetValue.disable()
    }
    
  }

  cancelForm() {
    if (this.colletralDetails && this.colletralDetails.length > 0) {
      this.buildCollateralVerification(this.colletralDetails[0]);
    } else {
      this.showForm = false;
    }
    this.isFormDisabled = true;
  }

  //this function will get either this menu item  is editable or readonly 
  getMenuItemAccess(){
    this.applicationService.getApplicationAccessModifiers(this.application.uid)
        .subscribe((response) => {
          this.applicationAccessSpecifiers = response.body;
          this.menuItemAccessSpecifier = this.applicationAccessSpecifiers
            .find(accessSpecifier => accessSpecifier.category === this.menuCode);
          if(this.menuItemAccessSpecifier){
          this.menuItemAllowAccess = this.menuItemAccessSpecifier.allowAccess;
            }
      });
    }

    
//this function open up the image upload dialog box
  //if file upload should allow single and multiple file upload, we need to pass "uploadType":"multiple"
  openImageFileUploadDialog() {
    if(this.selectedCollateral.collateral != null){
       this.fileUploadService.open({ "uploadType": "multiple", "folderUid": this.selectedCollateral.collateral.fileFolderUid, "applicationUid": this.application.uid, "files":this.allFilesInFolder.fileInfo })
      this.fileUploadService.confirmed().subscribe(data=>{
        this.getSelectedFolderFiles(data.folderUid)
      })
    
    }else{
      this._snackbar.open("Please add a folder to upload files", "Close", {
        duration: 5000,
      });
    }
  }


  getSelectedFolderFiles(folderUid) {
    this.applicationService.getAllFilesInApplicationFolder(this.application.uid,folderUid).subscribe((response: any) => {
      if (response) {
        this.allFilesInFolder = response.body;
        this.allFilesInFolder.fileInfo.forEach(element => {
          if (element.fileSize) {
            element["convertedFileSize"] = this.applicationService.bytesToSize(element.fileSize)
          }
        });
      }
    });
  }

  //this function will call onclick of download icon in file's section
  downloadFile(file) {
    this.applicationService.downloadFileFromApplicationFolder(this.application.uid, file.folderUid, file.fileId).subscribe(image => {
      const blob = new Blob([image.data], { type: file.type });
      fileSaver.saveAs(blob, file.fileName);
      this._snackbar.open("File has been downloaded successfully", "Close", {
        duration: 5000,
      });
    })
  }

  //this function will give the details of collateral finalized or not
  getCollateralStatus() {
    this.applicationService.getCollateralStatus(this.application.uid)
      .subscribe(response => {
        this.isCollateralFinalized = response.body.collateralFinalized;
    });
  }

  //this function will call to get basic application data 
// to refresh "this.application" values after particular action eg: after decision save like proceed in sidenav
refreshApplicationData() {
  this.applicationService.getBasicApplicationInfo(
    this.application.uid, 'ApplicationOnly').subscribe((response) => {
      this.application = response.body.applicationDTO;
      this.getRefCodes();
  });
}


   //this function will be called on click of delete icon on file list
   deleteFile(selectedFile){
    this.applicationService.fileDelete(this.application.uid, selectedFile.uid).subscribe((response) => {
      if(response.body.status == "success") {
      this._snackbar.open("File deleted successfully", "Close", {
        duration: 2000,
      });
      this.getSelectedFolderFiles(this.selectedCollateral.collateral.fileFolderUid);
    } else {
      let errorMessage =[]
      errorMessage.push("File deleted failed")
      errorMessage.push(response.body.message)
      this.applicationService.displayErrorMessages(errorMessage);
    }}, (failure) => {
      let errorMessage =[]
      errorMessage.push("File deleted failed")
      errorMessage.push(failure)
      this.applicationService.displayErrorMessages(errorMessage);
    });
  }
  
    openFileDeleteConfirmation(selectedFile){
        this.confirmationDialogService.open({ "btnCancelText":"Cancel","btnConfirmText":"Delete","message":"Are you sure you want to delete this File?"})
        this.confirmationDialogService.confirmed().subscribe(data=>{
          if(data){
          this.deleteFile(selectedFile)
          }
        })
      
    }

      //this function will trigger on clicking of image,will open up a mat-dialog
   openContentElement() {
    this.imagePreviewDialogService.open({ "images": this.allFilesInFolder })
  }
} 