import { Component, OnInit, Input } from '@angular/core';
import { LoanApplication,ApplicationAccessSpecifier } from '@app/applications/applications.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApplicationService } from '@app/applications/application.service';
import { CFASummary, BusinessIncome } from '../financials.model';
import { NameValueDto } from '@ig-core/interfaces/name-value-dto';
import { ReferenceCodeService } from '@app/admin/reference-code/reference-code.service';
import { MatSnackBar } from '@angular/material';
@Component({
  selector: 'eng-cfa',
  templateUrl: 'cfa.template.html',
  styleUrls: ['../../application-details.styles.scss', 'cfa.styles.scss']
})
export class CFAComponent implements OnInit {

  @Input()
  application: LoanApplication;
  @Input()
  menuCode:string;

  updatedApplicableRatioPercentageValue:number;

  cfaSummary: CFASummary = new CFASummary();

  cfaSummaryForm: FormGroup;

  showForm: boolean;
  isFormDisabled: boolean;
  allowAccess: Boolean;
  cfaFinalized: boolean;
  menuItemAllowAccess:boolean;
  applicationFinalApprovedFlag:boolean;

  businessIncomeTypes: NameValueDto[];
  selectedBusinessIncome: BusinessIncome;
  cfaFinalizedBusinessIncome: BusinessIncome;

  applicationAccessSpecifiers: ApplicationAccessSpecifier[];
  menuItemAccessSpecifier: ApplicationAccessSpecifier;
  // this boolean is used to show field names based on cfaMethod
  showDisposableIncomeFields: boolean;

  constructor(private formBuilder: FormBuilder,
    private applicationService: ApplicationService,
    private referenceCodeService: ReferenceCodeService,
    private _snackbar: MatSnackBar) {
    this.allowAccess = this.applicationService.allowAccess;
  }

  ngOnInit() {
    this.applicationFinalApprovedFlag=this.application.finalApprovedFlag
    this.getCfaStatus();
    this.fetchCfaSummary();
    this.getMenuItemAccess();

    this.isFormDisabled = true;
  }

  fetchCfaSummary() {
    this.showForm = false;
    this.showDisposableIncomeFields = false;
    this.applicationService.getCfaDetails(this.application.uid)
      .subscribe((response) => {
        this.cfaSummary = response.body;
        if(this.cfaSummary.cfaMethod === 'CFA-DISPOSABLEINC-FOIR'){
          this.showDisposableIncomeFields = true;
        }
        
        //this filter will check for finalized business income details and stored in "cfaFinalizedBusinessIncome" parameter
        //if none of the business income finalized the "cfaFinalizedBusinessIncome" parameter is empty
        this.cfaFinalizedBusinessIncome = this.cfaSummary.businessIncomes
          .find(businessIncome => businessIncome.useInCfaFlag === true);
        
        if(this.cfaFinalizedBusinessIncome) {
          this.selectedBusinessIncome = this.cfaFinalizedBusinessIncome;
        } else this.selectedBusinessIncome = this.cfaSummary.businessIncomes[0];
        
        this.processCfaResponse();
      });
  }

  processCfaResponse() {
    this.referenceCodeService.getShortRefCodes('business_income')
      .subscribe((response: any) => {
        this.businessIncomeTypes = response.business_income;
        this.businessIncomeTypes = this.businessIncomeTypes.filter(businessIncomeType =>
          this.cfaSummary.businessIncomes.find(businessIncome =>
            businessIncome.incomeDocumentType === businessIncomeType.code));
        this.showForm = this.businessIncomeTypes.length > 0;
        this.buildCfaSummaryForm();
      });
  }

  changeSelectedBusinessIncome(businessIncome: BusinessIncome) {
    this.showForm = true;
    this.selectedBusinessIncome = businessIncome;
    this.buildCfaSummaryForm();
  }

  buildCfaSummaryForm() {
    if (this.showForm) {
      this.cfaSummaryForm = this.formBuilder.group({
        proposedAmount: this.cfaSummary.loanAmountProposed,
        emi: this.cfaSummary.emiAmount,
        affordableEmi: '',
        monthlySales: '',
        businessIncome: '',
        eligibleMonthlyTurnover: '',
        totalOperatingExpenses: this.cfaSummary.totalBusinessExpense,
        netBusinessIncome: '',
        incomeAsPercentageOfGrossSales: '',
        totalAdditionalIncome: this.cfaSummary.totalAdditionalIncome,
        eligibleAdditionalIncome: '',
        totalPersonalExpenses: this.cfaSummary.totalPersonalExpense,
        netIncomePostExpenses: '',
        totalEmiLiabilities: this.cfaSummary.totalLiabilityRepayment,
        totalMonthlySurplus: '',
        applicableRatio: '',
        emiAsPerDSR: '',
        customerAffordableEmi: '',
        finalEmiEligibility: '',
        actualEmiOffered: '',
        foir: ''
      });
      this.populateEmiEligilibilityFields();
    }
  }

  populateEmiEligilibilityFields() {
    if (this.selectedBusinessIncome) {
      this.cfaSummaryForm.patchValue({
        businessIncome: this.selectedBusinessIncome.incomeDocumentType,
        affordableEmi: this.selectedBusinessIncome.preCalValues.affordableEmi,
        monthlySales: this.selectedBusinessIncome.monthlySales,
        eligibleMonthlyTurnover: this.selectedBusinessIncome.eligibleMonthlyTurnover,
        netBusinessIncome: this.selectedBusinessIncome.preCalValues.netBusinessIncome,
        incomeAsPercentageOfGrossSales: (this.selectedBusinessIncome.preCalValues.incomeAsPctOfGrossSales% 1 === 0)?this.selectedBusinessIncome.preCalValues.incomeAsPctOfGrossSales:this.selectedBusinessIncome.preCalValues.incomeAsPctOfGrossSales.toFixed(2),
        eligibleAdditionalIncome: this.selectedBusinessIncome.preCalValues.eligibleFamilyIncome,
        netIncomePostExpenses: this.selectedBusinessIncome.preCalValues.netIncomePostExpenses,
        totalMonthlySurplus: this.selectedBusinessIncome.preCalValues.monthlySurplus,
        applicableRatio: (this.selectedBusinessIncome.preCalValues.applicableRatio% 1 === 0)?this.selectedBusinessIncome.preCalValues.applicableRatio:this.selectedBusinessIncome.preCalValues.applicableRatio.toFixed(2),
        emiAsPerDSR: this.selectedBusinessIncome.preCalValues.emiAsPerDSR,
        customerAffordableEmi: this.selectedBusinessIncome.preCalValues.affordableEmi,
        finalEmiEligibility: this.selectedBusinessIncome.preCalValues.finalEmiEligibility,
        actualEmiOffered: this.selectedBusinessIncome.preCalValues.actualEmi,
        foir: (this.selectedBusinessIncome.preCalValues.foir% 1 === 0)?this.selectedBusinessIncome.preCalValues.foir:this.selectedBusinessIncome.preCalValues.foir.toFixed(2)
      });
    }
  }

  updateEmiEligilibility() {
    this.selectedBusinessIncome = this.cfaSummary.businessIncomes.find(
      businessIncome => businessIncome.incomeDocumentType ===
        this.cfaSummaryForm.value.businessIncome);
    this.populateEmiEligilibilityFields();
  }

  finalizeCfa() {
    this.applicationService.finalizeCfa(this.application.uid,
      this.selectedBusinessIncome.uid).subscribe(
        (_success) => {
          this.cfaSummary = _success.body;
          this.cfaFinalizedBusinessIncome = this.cfaSummary.businessIncomes
            .find(businessIncome => businessIncome.useInCfaFlag === true);
          this.cfaFinalized = true;
          this.showCfaFinalizedMessage();
        },(_failure) => {
          let errormesg =[]
            errormesg.push("Finalize CFA Faild.")
            errormesg.push(_failure)
            this.applicationService.displayErrorMessages(errormesg);
        }
      );
  }

  reviseCfa() {
    this.applicationService.reviseCfa(this.application.uid,
      this.cfaFinalizedBusinessIncome.uid).subscribe(
        (_success) => {
          this.cfaFinalized = false;
          this.cfaFinalizedBusinessIncome = undefined;
        },(_failure) => {
          let errormesg =[]
            errormesg.push("Revise CFA Faild.")
            errormesg.push(_failure)
            this.applicationService.displayErrorMessages(errormesg);
        }
      );
  }

  enableDisableForm() {
    this.isFormDisabled = !this.isFormDisabled;
  }

  cancelForm() {
    this.buildCfaSummaryForm();
    this.isFormDisabled = true;
  }

  //this function will call a api to get CFA Finalized or not status
  getCfaStatus() {
    this.applicationService.getCfaStatus(this.application.uid).subscribe(response => {
      this.cfaFinalized = response.body.cfaFinalized
      if (this.cfaFinalized) {
        this.showCfaFinalizedMessage();
      }
    })
  }

  showCfaFinalizedMessage() {
    this._snackbar.open("CFA is finalized. Financial data cannot be modified", "Close", {
      duration: 5000,
    });
  }

  //this function will get either this menu item  is editable or readonly 
  getMenuItemAccess(){
    this.applicationService.getApplicationAccessModifiers(this.application.uid)
        .subscribe((response) => {
          this.applicationAccessSpecifiers = response.body;
          this.menuItemAccessSpecifier = this.applicationAccessSpecifiers
            .find(accessSpecifier => accessSpecifier.category === this.menuCode);
          if(this.menuItemAccessSpecifier){
          this.menuItemAllowAccess = this.menuItemAccessSpecifier.allowAccess;
            }
      });
    }
    updateApplicableRatioPercentage(){
      if(this.updatedApplicableRatioPercentageValue!= 0 && this.updatedApplicableRatioPercentageValue>= 1&&this.updatedApplicableRatioPercentageValue <=99){
      let requestDSR={
        "dsrRatio":this.updatedApplicableRatioPercentageValue      
      }
      this.applicationService.reviseDSR(this.application.uid,this.selectedBusinessIncome.uid,requestDSR).subscribe(response=>{
        this.selectedBusinessIncome.preCalValues.applicableRatio =this.updatedApplicableRatioPercentageValue
        this.fetchCfaSummary()
        this._snackbar.open(this.selectedBusinessIncome.preCalValues.ratioType + " value updated successfully", "Close", {
          duration: 5000,
        });
      })
    }else{
      this._snackbar.open(this.selectedBusinessIncome.preCalValues.ratioType + " value should be non-zero positive number between 1 to 99", "Close", {
        duration: 5000,
      });
    }
    }
    
}