import { Component, OnChanges, Input, SimpleChanges, OnInit } from '@angular/core';
import {BureauReport } from './bureau-reports.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ReferenceCodeService } from '@app/admin/reference-code/reference-code.service';
import { ApplicationService } from '@app/applications/application.service';
import { LoanApplication, Person,ApplicationAccessSpecifier } from '@app/applications/applications.model';
import { NameValueDto } from '@ig-core/interfaces/name-value-dto';
import { ValidationService } from '../../../application-validators/validation.service';
import { IgFileService, Files } from '@ig-core/form/igFile.service';
import { ImagePreviewDialogService } from '@app/utils/image-preview-dialog/image-preview-dialog.service';
import { DateAdapter, MatSnackBar } from '@angular/material';
import { SessionStorageService} from 'angular-web-storage';
import { DateFormatPipe } from '@app/utils/date-format.pipe';
import { FileUploadService } from '@app/utils/file-upload/file-upload.service';
import { File, Folder, FolderFilesComposite } from '../../loanInformation/loan-folder-files/loan-folder-files.model';
import { ConfirmationDialogService } from '@app/utils/confirmation-dialog/confirmation-dialog.service';
import * as fileSaver from 'file-saver';
import { SecureStorageService } from '@ig-core/authentication/secureStorage.service';
import { DocumentVerificationDTO } from '../../business/kycProofs/kyc.model';
import { BUREAU_VERIFICATION_RULE_LENIENT, BUREAU_VERIFICATION_RULE_STRICT, SOURCE_MANUAL } from '@app/constants/data.constants';
import { SOURCE_DIGITAL } from '@app/constants/data.constants';
import { BureauResponse } from '../../applicationDocument/xdata-service/xdata-response.model';
import { EXECUTE_POLICY } from '@app/constants/feature-set.constants';
import { ReferenceCode } from '@app/admin/reference-code/reference-code.model';
@Component({
  selector: 'eng-bureau-reports',
  templateUrl: './bureau-reports.component.html',
  styleUrls: ['../../application-details.styles.scss','./bureau-reports.component.css']
})
export class BureauReportsComponent implements OnInit, OnChanges {

  @Input()
  application: LoanApplication;

  @Input()
  applicant: Person;

  @Input()
    menuCode:string;

  applicantBureauReports: BureauReport[];
  applicantBureauReportsForm: FormGroup;
  selectedReport: BureauReport;
  bureauReportTypes: NameValueDto[];
  allFilesInFolder: FolderFilesComposite;
  applicationAccessSpecifiers: ApplicationAccessSpecifier[];
  menuItemAccessSpecifier: ApplicationAccessSpecifier;

  isFormDisabled: boolean;
  showForm: boolean;
  allowAccess:boolean;
 menuItemAllowAccess:boolean;

  documents: Files[] = [];
  bureauVerificationResponse: BureauResponse;
  loginUser:any
  // verifyButtonStatus this boolean is used to enable or disable verify button
  verifyButtonStatus: boolean;
  // showErrorCard this is used to show error card which is if any validationMessages is present
  showErrorCard: boolean;
  // this is used to show message next to verify button
  validationMessage: string;
  listOfFeatures: string[];
  sortSettingList: ReferenceCode[] = [];
  bureauVerificationRule: any;

  constructor(private formBuilder: FormBuilder,
    private referenceCodeService: ReferenceCodeService,
    private applicationService: ApplicationService,
    private validationService: ValidationService,
    private fileService: IgFileService,
    private _snackbar: MatSnackBar,
    private $sessionStorage:SessionStorageService,
    private customDatepipe: DateFormatPipe,
    private confirmationDialogService: ConfirmationDialogService,
    private fileUploadService: FileUploadService,
    private imagePreviewDialogService: ImagePreviewDialogService,
    private secureStorageService:SecureStorageService) {
    this.applicantBureauReportsForm = this.formBuilder.group({
      reportType: '',
      creditScore: '',
      reportNumber: null,
      reportDate:'',
      description:'',
      enquiry3m:'',
      enquiry6m:'',
      writeoff2y:'',
      writeoffTotal:'',
      totalEnquiries:''
    });
    this.allowAccess = this.applicationService.allowAccess;
  }

  ngOnInit() {
    this.loginUser = this.secureStorageService.secureSessionStorage.getItem("logInUserDetails")
    this.applyValidatorsToapplicantBureauReports()
    this.getRefCodes();
   this.getMenuItemAccess();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.applicant) {
      this.isFormDisabled = true;
      this.applicantBureauReportsForm.disable()
      this.fetchApplicantBureauReports();
      this.filterMenuItemAccessInSpecifiers();
    }
  }

  getRefCodes() {
    this.referenceCodeService.getShortRefCodes('bureau_report_type').subscribe((response: any) => {
      this.bureauReportTypes = response.bureau_report_type;
    });

    this.referenceCodeService.getRefCodesForClassifier('settings').subscribe((response: any) => {
      this.sortSettingList = response;
      this.sortSettingList.forEach(element => {
        if(element.code == "BUREAU-VERIFICATION-RULE"){
          if (element.field1 !== null){
          this.bureauVerificationRule = element.field1;
          }
        }
      })
    })

    // this is to get what are the features present in featureset
    this.referenceCodeService.getFeatureSet().subscribe(response =>{
      this.listOfFeatures = response.body.map(feature => feature.toLocaleLowerCase());
    })
  }

  fetchApplicantBureauReports() {
    this.showForm = false;
    this.applicationService.getPersonBureauReports(this.application.uid, this.applicant.uid)
      .subscribe((response: any) => {
        if (response) {
          this.applicantBureauReports = response.body;
          this.applicantBureauReports.forEach((element, index) => {
            this.applicantBureauReports[index]["deleteBtnDisabled"] = true;
          });
          this.showForm = (this.applicantBureauReports && this.applicantBureauReports.length > 0);
          this.buildApplicantDocumentForm();
        }
      });
  }

  changeSelectedReport(bureauReport?: BureauReport, index?: number, from?: string) {
    if (!bureauReport) {
      bureauReport = new BureauReport();
    }
    this.showForm = true;
    this.applicantBureauReports.forEach((element, i) => {
      this.applicantBureauReports[i].deleteBtnDisabled = true;
    });
    if (from != "new") {
      this.applicantBureauReports[index].deleteBtnDisabled = false;
    }
    this.buildApplicantDocumentForm(bureauReport);
  }

  buildApplicantDocumentForm(applicantBureauReports?: BureauReport) {
    // if we select any other report then we are resetting values to default value then we are building the form
    this.bureauVerificationResponse = null;
    this.verifyButtonStatus = false;
    this.showErrorCard = false;
    this.validationMessage = '';
    if (this.showForm) {
      if (!applicantBureauReports) if (!applicantBureauReports){
        applicantBureauReports = this.applicantBureauReports[0];
        this.applicantBureauReports[0].deleteBtnDisabled = false;
      }
      this.applicantBureauReportsForm.patchValue({
        reportType: applicantBureauReports.bureauReportType != null?applicantBureauReports.bureauReportType:"",
        creditScore: applicantBureauReports.creditScore!= null?applicantBureauReports.creditScore:"",
        reportNumber: applicantBureauReports.reportNumber!= null?applicantBureauReports.reportNumber:null,
        enquiry3m: applicantBureauReports.enquiry3m!= null?applicantBureauReports.enquiry3m:null,
        enquiry6m: applicantBureauReports.enquiry6m!= null?applicantBureauReports.enquiry6m:null,
        writeoff2y: applicantBureauReports.writeoff2y!= null?applicantBureauReports.writeoff2y:null,
        reportDate:applicantBureauReports.reportDate!= null?new Date(applicantBureauReports.reportDate):"",
        description:applicantBureauReports.reportDescription!= null?applicantBureauReports.reportDescription:"",
        writeoffTotal: applicantBureauReports.writeoffTotal!=null?applicantBureauReports.writeoffTotal:"",
        totalEnquiries: applicantBureauReports.enquiryTotal!=null?applicantBureauReports.enquiryTotal:""
      });
      this.selectedReport = applicantBureauReports;
      this.getSelectedFolderFiles(this.selectedReport.fileFolderUid);

      // we are calling getBureauReportVerificationEvidence only if the selected report source is digital,
      // and if it has bureauVerificationResponse then only we are displaying the REPORT HIGHLIGHTS card
      if (this.selectedReport.source === SOURCE_DIGITAL) {
        this.applicationService.getBureauReportVerificationEvidence(this.application.uid, this.selectedReport.uid,).subscribe(response => {
          if (response.body) {
            this.bureauVerificationResponse = response.body
            
            // if featureset includes validateBureau then only we need to show execute button and message hence we are checking it first
            if (this.listOfFeatures.includes(EXECUTE_POLICY)) {
              // these are conditions to be met for enabling verify button, if any of the condtion is not met verifyButton will be disabled
              let validationDTO = this.bureauVerificationResponse.validationDetailDTO;
              // here all these conditions need to be met for enabling verify button, if we write all condition in single if it will be confusing.
              // hence we have split the conditions into two, first we are checking if it included authorities and verifiedFlag is false or null this is common.
              let verifyButtonConditionsMet = (this.loginUser.authorities.includes("VERIFY-BUREAU") || this.loginUser.authorities.includes("VERIFY-BUREAU-OFFSET")) &&
                (this.selectedReport.verifiedFlag === null || this.selectedReport.verifiedFlag === false);
              
              //if the above condition is met above condition is common for both and now we are checking for bureau verification rule is strict or not,
              // If bureauVerificationRule is strict, then both acceptanceCriteriaMet and inPermissibleTolerance should be true.
              // else if bureauVerificationRule is Linient, then inPermissibleTolerance should be true. 
              if (verifyButtonConditionsMet) {
                if ((this.bureauVerificationRule === BUREAU_VERIFICATION_RULE_STRICT && validationDTO.acceptanceCriteriaMet && validationDTO.inPermissibleTolerance) ||
                  (this.bureauVerificationRule === BUREAU_VERIFICATION_RULE_LENIENT && validationDTO.inPermissibleTolerance)) {
                  this.verifyButtonStatus = true;
                }
              }
              this.setValidationMessage()
            }
          }
        })
      }
    }
  }

  setValidationMessage(){
    let validationDTO = this.bureauVerificationResponse.validationDetailDTO;

    if (this.selectedReport.verifiedFlag) {
      this.validationMessage = "Bureau report is verified";
    } else if (!validationDTO.inPermissibleTolerance) {
      this.validationMessage = "Cannot verify, found one or more validation errors";
    } else if(!validationDTO.acceptanceCriteriaMet && validationDTO.inPermissibleTolerance) {
      this.validationMessage = "Found one or more validation errors";
    }

    // if there is any validationMessage then only we are showing the error card
    if(this.bureauVerificationResponse.validationDetailDTO.validationMessages.length > 0){
      this.showErrorCard = true;
    }
  }

  enableDisableForm() {
    if(this.selectedReport.source === SOURCE_DIGITAL){
      this.isFormDisabled = true;
      this.applicantBureauReportsForm.disable();
      this._snackbar.open("Bureau report generated. Edit not allowed", "Close" ,{
        duration: 5000
      })
      return;
    }
    this.isFormDisabled = false;
    this.applicantBureauReportsForm.enable()
  }

  cancelForm() {
    this.applicantBureauReports.forEach((element, i) => {
      this.applicantBureauReports[i].deleteBtnDisabled = true;
    });
    if (this.applicantBureauReports && this.applicantBureauReports.length > 0) {
      this.applicantBureauReports[0].deleteBtnDisabled = false;
      this.buildApplicantDocumentForm(this.applicantBureauReports[0]);
    } else {
      this.showForm = false;
    }
    this.isFormDisabled = true;
    this.applicantBureauReportsForm.disable()
  }
  //this function will apply validators to form group
  applyValidatorsToapplicantBureauReports() {
    this.validationService.applyValidationRules(this.applicantBureauReportsForm, "applicantBureauReports").then((controlValidators) => {
    }).catch(() => {
    })
  }

  //this function open up the image viewer dailog box
  openImagePreviewDialog() {
    this.imagePreviewDialogService.open({ "images": this.allFilesInFolder })
  }

  //this function will get either this menu item  is editable or readonly 
  getMenuItemAccess(){
    this.applicationService.getApplicationAccessModifiers(this.application.uid)
        .subscribe((response) => {
          this.applicationAccessSpecifiers = response.body;
         this.filterMenuItemAccessInSpecifiers()
      });
    }

    filterMenuItemAccessInSpecifiers(){
      this.menuItemAccessSpecifier = this.applicationAccessSpecifiers
      .find(accessSpecifier => accessSpecifier.category === this.menuCode);
    if(this.menuItemAccessSpecifier){
    this.menuItemAllowAccess = this.menuItemAccessSpecifier.allowAccess;
      }
    }
//this function is called onclick of '+' button,
//after clicking the new empty form will open to enter details
addNewDocument(){
  this.showForm = true;
  let newReport =new BureauReport();
  this.buildApplicantDocumentForm(newReport);

  this.isFormDisabled = false;
  this.applicantBureauReportsForm.enable()
  this.changeSelectedReport(newReport, undefined, "new");
  }

    //this function is called onclick of save button
saveBureauReport(){
  //this will mark touched all controls in formgroup
  this.validationService.markFormGroupTouched(this.applicantBureauReportsForm)
  //check the document form is valid or not,if valid then only save will happen 
  if(this.applicantBureauReportsForm.valid && !this.isFormDisabled){
    let todaydate = new Date();
    let savingReport = this.selectedReport
    let reportFormFields = this.applicantBureauReportsForm.value;
    savingReport.bureauReportType = reportFormFields.reportType;
    savingReport.creditScore = reportFormFields.creditScore;
    savingReport.reportNumber = reportFormFields.reportNumber;
    savingReport.enquiry3m = reportFormFields.enquiry3m;
    savingReport.enquiry6m = reportFormFields.enquiry6m;
    savingReport.writeoff2y = reportFormFields.writeoff2y;
    savingReport.reportDate = this.customDatepipe.transform(
      reportFormFields.reportDate, 'ISODATE');
    savingReport.reportDescription = reportFormFields.description;
    savingReport.writeoffTotal = reportFormFields.writeoffTotal;
    savingReport.enquiryTotal = reportFormFields.totalEnquiries;
    savingReport.source = this.selectedReport.source ? this.selectedReport.source : SOURCE_MANUAL;
    this.applicationService.saveapplicantBureauReports(this.application.uid,
      this.applicant.uid, savingReport).subscribe((response) => {
        this.applicantBureauReportsForm.disable()
          this._snackbar.open("Report updated successfully", "Close", {
            duration: 2000,
          });
          this.isFormDisabled = true;
          this.fetchApplicantBureauReports();
        }, (failure) => {
          let errormesg =[]
          errormesg.push("Report update failed, ")
          errormesg.push(failure)
          this.applicationService.displayErrorMessages(errormesg);
        }
      );
  }
}

//this function open up the image upload dailog box
  //if file upload should allow single and multiple file upload, we need to pass "uploadType":"multiple"
  openImageFileUploadDialog() {
    if(this.selectedReport.fileFolderUid){
       this.fileUploadService.open({ "uploadType": "multiple", "folderUid": this.selectedReport.fileFolderUid, "applicationUid": this.application.uid, "files":this.allFilesInFolder.fileInfo })
      this.fileUploadService.confirmed().subscribe(data=>{
        this.getSelectedFolderFiles(data.folderUid)
      })
    }else{
      this._snackbar.open("Please add a folder to upload files", "Close", {
        duration: 5000,
      });
    }
  }


  getSelectedFolderFiles(folderUid) {
    if(folderUid){
    this.applicationService.getAllFilesInApplicationFolder(this.application.uid,folderUid).subscribe((response: any) => {
      if (response) {
        this.allFilesInFolder = response.body;
        this.allFilesInFolder.fileInfo.forEach(element => {
          if (element.fileSize) {
            element["convertedFileSize"] = this.applicationService.bytesToSize(element.fileSize)
          }
        });
      }
    });
  }
  }

  //this function will call onclick of download icon in file's section
  downloadFile(file) {
    this.applicationService.downloadFileFromApplicationFolder(this.application.uid, file.folderUid, file.fileId).subscribe(image => {
      const blob = new Blob([image.data], { type: file.type });
      fileSaver.saveAs(blob, file.fileName);
      this._snackbar.open("File has been downloaded successfully", "Close", {
        duration: 5000,
      });
    })
  }

  

  //this function will be called on click of delete icon on Document
  bureauReportdelete(selectedReport) {
    this.applicationService.bureaureportsDelete(this.application.uid, selectedReport.uid).subscribe((response) => {
      if(response.body.status == "success") {
      this._snackbar.open("Bureau report deleted successfully", "Close", {
        duration: 2000,
      });
      this.fetchApplicantBureauReports();
    } else {
      let errormesg = []
      errormesg.push("Bureau report deleted failed")
      errormesg.push(response.body.message);
      this.applicationService.displayErrorMessages(errormesg);
    }}, (failure) => {
      let errormesg = []
      errormesg.push("Bureau report deleted failed")
      errormesg.push(failure)
      this.applicationService.displayErrorMessages(errormesg);
    });
  }

  openDeleteConfirmation() {
    this.confirmationDialogService.open({ "btnCancelText": "Cancel", "btnConfirmText": "Delete", "message": "Are you sure you want to delete this Bureau report?" })
    this.confirmationDialogService.confirmed().subscribe(data => {
      if (data) {
        this.bureauReportdelete(this.selectedReport)
      }
    })
  }

  //this function will be called on click of delete icon on file list
deleteFile(selectedFile){
  this.applicationService.fileDelete(this.application.uid, selectedFile.uid).subscribe((response) => {
    if(response.body.status == "success") {
    this._snackbar.open("File deleted successfully", "Close", {
      duration: 2000,
    });
    this.getSelectedFolderFiles(this.selectedReport.fileFolderUid);
  } else {
    let errormesg =[]
    errormesg.push("File deleted failed")
    errormesg.push(response.body.message)
    this.applicationService.displayErrorMessages(errormesg);
  }}, (failure) => {
    let errormesg =[]
    errormesg.push("File deleted failed")
    errormesg.push(failure)
    this.applicationService.displayErrorMessages(errormesg);
  });
}

  openfileDeleteConfirmation(selectedFile){
      this.confirmationDialogService.open({ "btnCancelText":"Cancel","btnConfirmText":"Delete","message":"Are you sure you want to delete this File?"})
      this.confirmationDialogService.confirmed().subscribe(data=>{
        if(data){
        this.deleteFile(selectedFile)
        }
      })
    
  }

  viewPdf(file){
    this.fileService.fileStreamById(file.fileId,"false").subscribe(res =>{
      window.open(res.data, '_blank');
    })
  }

  onVerifyClick() {
    this.applicationService
      .verifyBureauDetails(this.application.uid, this.selectedReport.uid, this.bureauVerificationResponse)
      .subscribe((response) => {
          if(response.body.verified){
            this.validationMessage = "Bureau report is verified";
            this.verifyButtonStatus = false;
            this.fetchApplicantBureauReports();
          }
          this._snackbar.open(response.body.message, "Close",{
            duration: 5000
          })
      });    
  }
}
