import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Route } from '@angular/router';
import { MaterialLibModule } from '@app/material-lib.module';
import { AuthenticatedUserService } from '@ig-core/authentication/authenticated-user-service';
import { XDataServiceComponent } from '@app/applications/application-details/applicationDocument/xdata-service/xdata-service.component';
import { AllPipesModule } from '@app/utils/pipes.module';
import { BureauComponent } from './bureau-services/bureau.component';
import { EkycComponent } from './kyc-services/ekyc.component';
import { KycVerificationComponent } from './kyc-verification/kyc-verification.component';
import { PennyDropComponent } from './penny-drop/penny-drop.component';
import { BookVoucherComponent } from './book-voucher/book-voucher.component';
import { EsignComponent } from './e-sign/e-sing.component';
import { BankAnalyzerComponent } from './bank-analyzer/bank-analyzer.component';

export const Services: Route = {
  path: 'services',
  canActivate: [AuthenticatedUserService],
  children: [
    {
      path: '',
      component: XDataServiceComponent,
    },
    {
      path: 'bureau',
      component: BureauComponent
    },
    {
      path: 'ekyc',
      component: EkycComponent
    },
    {
      path: 'pan',
      component: KycVerificationComponent
    },
    {
      path: 'vtrid',
      component: KycVerificationComponent
    },
    {
      path: 'penny_drop',
      component: PennyDropComponent,
    },
    {
      path: 'voucher',
      component: BookVoucherComponent,
    },
    {
      path: 'esign',
      component: EsignComponent,
    },
    {
      path: 'bnkstmt',
      component: BankAnalyzerComponent,
    },
    {
      path: 'estamp',
      component: EsignComponent,
    },
  ]
};

@NgModule({
    imports: [
      //ReportsRoutingModule,
      BrowserModule,
      HttpClientModule,
      MaterialLibModule,
      FormsModule,
      RouterModule,
      FlexLayoutModule,
      ReactiveFormsModule,
      AllPipesModule
    ],
    declarations: [],
    exports:[]
})
export class ServicesModule { }