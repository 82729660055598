import {Component,OnInit,Input} from "@angular/core";
import {LoanApplication,ApplicationAccessSpecifier,Person} from "@app/applications/applications.model";
import {FormArray,FormBuilder,FormControl,FormGroup,Validators,} from "@angular/forms";
import { ApplicationService } from "@app/applications/application.service";
import { ValidationService } from "@app/applications/application-validators/validation.service";
import { LoanDocumentDTO, LoanDocumentSignersDto, SignerDTO } from "./loan-document.model";
import { FolderFilesComposite } from "../loan-folder-files/loan-folder-files.model";
import { ImagePreviewDialogService } from "@app/utils/image-preview-dialog/image-preview-dialog.service";
import FileSaver from "file-saver";
import { MatSnackBar } from "@angular/material";
import { ReferenceCodeService } from "@app/admin/reference-code/reference-code.service";
import { NameValueDto } from "@app/loan-od-accounts/name-value-dto";
import { FileUploadService } from "@app/utils/file-upload/file-upload.service";
import { IgFileService } from "@ig-core/form/igFile.service";
import { ControlValidators } from "@app/applications/application-validators/control-validators.directive";
@Component({
  selector: "eng-loan-document",
  templateUrl: "./loan-document.template.html",
  styleUrls: ["../../application-details.styles.scss", "./loan-document.styles.scss"],
})
export class LoanDocumentComponent implements OnInit {
  @Input() application: LoanApplication;
  @Input() applicants: Person[];
  @Input() menuCode: string;

  allowAccess: Boolean;
  menuItemAllowAccess: boolean;
  showForm: boolean;
  signerType: NameValueDto[];
  isFormDisabled: boolean;

  applicationAccessSpecifiers: ApplicationAccessSpecifier[];
  menuItemAccessSpecifier: ApplicationAccessSpecifier;

  signersForm: FormGroup;
  addNewRecord: FormGroup;
  addEstampDetails: FormGroup;
  listOfSigners: SignerDTO[]=[];

  loanDocumets: LoanDocumentDTO[] = [];
  selectedLoanDocument: LoanDocumentDTO;
  allFilesInFolder: FolderFilesComposite;
  displayMessage: string = "";
  loanDocumentSignersDto: LoanDocumentSignersDto = {};
  eStampDetails: NameValueDto[];
  listOfFeatures: string[];

  constructor(
    private formBuilder: FormBuilder,
    private imagePreviewDialogService: ImagePreviewDialogService,
    private applicationService: ApplicationService,
    private validationService: ValidationService,
    private _snackbar: MatSnackBar,
    private referenceCodeService: ReferenceCodeService,
    private fileService: IgFileService
  ) {
    this.allowAccess = this.applicationService.allowAccess;
  }
  ngOnInit() {
    this.addEstampDetails = this.formBuilder.group({
      eStampActiveFlag: [false],
      eStampCode: '',
      tagName: '',
    });
    this.isFormDisabled = true;
    this.getRefcode();
    this.getLoanDocument();
    this.getMenuItemAccess();
    this.setDefaultFormValue();
    this.getEstampTags();
  }

  getRefcode(){
    this.referenceCodeService.getShortRefCodes('signerType').subscribe((response: any) => {
      this.signerType = response.signerType;
    });

    this.referenceCodeService.getFeatureSet().subscribe(response =>{
      this.listOfFeatures = response.body.map(feature => feature.toLocaleLowerCase());
    })
  }

  getLoanDocument() {
    this.showForm = false;
    let category = "ALL";
    this.applicationService.getLoanDocument(this.application.uid,category).subscribe((respose) => {
        this.loanDocumets = respose.body;
        if (this.loanDocumets.length > 0) {
          this.showForm = true;
          this.changeselectedLoanDocument(this.loanDocumets[0],0);
        }
      });
  }

  changeselectedLoanDocument(loanDocument,index?: number) {
    this.selectedLoanDocument = loanDocument;
    this.loanDocumentSignersDto = new LoanDocumentSignersDto();
    this.loanDocumets.forEach((element, i) => {
      this.loanDocumets[i].refreshBtnDisabled = true;
    });
    this.loanDocumets[index].refreshBtnDisabled = false;
    this.getSelectedFolderFiles(loanDocument.folderUid);
    this.getSigners(loanDocument.uid);
  }

  getSelectedFolderFiles(folderUid) {
    if (folderUid) {
      this.applicationService.getAllFilesInApplicationFolder(this.application.uid, folderUid).subscribe((response: any) => {
        if (response) {
          this.allFilesInFolder = response.body;
          this.allFilesInFolder.fileInfo.forEach(element => {
            if (element.fileSize) {
              element["convertedFileSize"] = this.applicationService.bytesToSize(element.fileSize)
            }
          });
        }
      });
    }(error) => {
          this.allFilesInFolder = {
            folderInfo: {},
            fileInfo: [],
          };
        }
  }

  getSigners(loanDocumentUid){
    this.applicationService.getSigners(this.application.uid, loanDocumentUid).subscribe(response =>{
      this.listOfSigners = response.body
      if(this.listOfSigners){
        if(this.selectedLoanDocument.status){
          this.listOfSigners = this.listOfSigners.filter(item => item.activeFlag)
        }
        this.checkSignersStatus();
        this.buildForm();
      }
    })
  }

  buildForm() {
    this.signersForm = this.formBuilder.group({
      listOfSigners: this.formBuilder.array([]),
    });
    this.addEstampDetails.patchValue({
      eStampActiveFlag: this.selectedLoanDocument.category === 'estamp',
      eStampCode: this.selectedLoanDocument.estampCode,
      tagName: this.selectedLoanDocument.estampTag
    });
    this.updateFormValues(this.listOfSigners);
    this.disableForm();
  }

  // this method is used to populate the form with data
  updateFormValues(listOfSigners){
    const array = this.signersForm.controls.listOfSigners as FormArray;
    listOfSigners.forEach((element) => {
      const group = new FormGroup({
        applicantName: new FormControl(element.name),
        partyPlay: new FormControl(element.type),
        phoneNumber: new FormControl(element.mobileNumber, [Validators.required, Validators.pattern("((\\+91-?)|0)?[0-9]{10}")]),
        status: new FormControl(element.status),
        activeFlag: new FormControl(element.activeFlag)
      });
      array.push(group);
    })
  }

  addSigners() {
    this.validationService.markFormGroupTouched(this.addNewRecord);
    if (this.addNewRecord.valid) {
      let newSigner: SignerDTO = {
        name: this.addNewRecord.controls.newName.value,
        mobileNumber: this.addNewRecord.controls.newPhoneNumber.value,
        type: this.addNewRecord.controls.type.value,
        activeFlag: true
      };
      // here we are calling UpdateValues to reflect the values of new signers details, and then pushed to listofSigners
      this.updateFormValues([newSigner]);
      this.listOfSigners.push(newSigner)
      this.setDefaultFormValue();
      this.enableForm();
    }
  }

  setDefaultFormValue(){
    this.addNewRecord = this.formBuilder.group({
      activeFlagSigner: [true],
      newName: ["", [Validators.required]],
      type: ['Other', [Validators.required]],
      newPhoneNumber: ["", [Validators.required, Validators.pattern("((\\+91-?)|0)?[0-9]{10}")]],
    });
  }

  //this function will call onclick of download icon in file's section
  downloadFile(file) {
    this.applicationService.downloadFileFromApplicationFolder(this.application.uid, file.folderUid, file.fileId).subscribe(image => {
      const blob = new Blob([image.data], { type: file.type });
      FileSaver.saveAs(blob, file.fileName);
      this._snackbar.open("File has been downloaded successfully", "Close", {
        duration: 5000,
      });
    })
  }

  saveSigners(){
    if (this.isFormDisabled) {
      return;
    }
    this.validationService.markFormGroupTouched(this.signersForm);
    this.validationService.markFormGroupTouched(this.addEstampDetails);
    if(this.signersForm.valid && this.addEstampDetails.valid){
      this.listOfSigners.forEach((element, index) => {
        element.activeFlag = this.signersForm.value.listOfSigners[index].activeFlag;
        element.mobileNumber = this.signersForm.value.listOfSigners[index].phoneNumber;
      })

      if(this.addEstampDetails.controls.eStampActiveFlag.value){
        this.loanDocumentSignersDto.category = 'estamp';
        this.loanDocumentSignersDto.estampCode = this.addEstampDetails.controls.eStampCode.value;
        this.loanDocumentSignersDto.estampTag = this.addEstampDetails.controls.tagName.value;
      }

      // we need to send only selected signers to server, hence we are filtering based on activeFlag true
      let selectedSingers = this.listOfSigners.filter(item => item.activeFlag);
      this.loanDocumentSignersDto.signersList = selectedSingers
      this.applicationService.updateLoanDocument(this.loanDocumentSignersDto, this.application.uid, this.selectedLoanDocument.uid).subscribe(response =>{
        this.loanDocumets.forEach((ele, index) =>{
          if(ele.uid === this.selectedLoanDocument.uid){
            this.loanDocumets[index] = response.body;
            this.changeselectedLoanDocument(response.body,index)
          }
        })
        this.isFormDisabled = true;
      },(failure) =>{
        this.loanDocumentSignersDto = new LoanDocumentSignersDto();
      })
    }else{
      this._snackbar.open("Please fill all mandatory fields", "Close", {
        duration: 4000,
      })
    }
  }

  refresh(){
    this.applicationService.checkEsignStatus(this.application.uid,this.selectedLoanDocument.uid).subscribe(response =>{
      if(response.body.status === 'SUCCESS'){
        this._snackbar.open(response.body.message, "close",{
          duration: 8000
        })
        // here we are updating the status from the response and we are doing getSelectedFolderFiles to get the 
        // esigned documents under documents.
        this.selectedLoanDocument.status = response.body.status;
        this.getSelectedFolderFiles(this.selectedLoanDocument.folderUid);
        this.getSigners(this.selectedLoanDocument.uid);
        this.isFormDisabled = true;
      }else{
        this.listOfSigners = response.body.signers
        this.listOfSigners = this.listOfSigners.filter(item => item.activeFlag)
        this.checkSignersStatus();
        this.buildForm();
      }
    })
  }

  checkSignersStatus(){
    // if partially signed we are showing this message out of selected signers how many signers signed the document
    // we are checking this for only selected signers. if completedSigners is zero means esign is yet to start
    this.displayMessage = "";
    let totalSigners = this.listOfSigners.length;
    let completedSigners = this.listOfSigners.filter(signer => signer.status === 'signed').length;

    if(completedSigners === 0){
      this.displayMessage = "";
    }else if (completedSigners < totalSigners) {
      this.displayMessage = completedSigners + " out of " + totalSigners + " signatures completed. ";
    }
  }

  downloadPartialSignedDocument(){
    this.applicationService.downloadPartialSignedDocument(this.application.uid,this.selectedLoanDocument.uid).subscribe(image => {
      const blob = new Blob([image.data], { type: image.data.type });
      FileSaver.saveAs(blob, image.filename);
      this._snackbar.open("Partially signed document has been downloaded successfully", "Close", {
        duration: 5000,
      });
    })
  }


  editLoanDocument(){
    if(this.selectedLoanDocument.status){
      this.disableForm();
      if(this.selectedLoanDocument.status === 'SUCCESS'){
      this._snackbar.open("Loan document eSign completed. Edit not allowed", "close" ,{
        duration: 7000
      })
    }else if(this.selectedLoanDocument.status === 'REQUESTED'){
      this._snackbar.open("Loan document eSign initiated. Edit not allowed", "close" ,{
        duration: 7000
      })
    }else if(this.selectedLoanDocument.status === 'FAILURE'){
      this._snackbar.open("Loan document eSign failed. Edit not allowed", "close" ,{
        duration: 7000
      })
    }
    return;
    }
    this.enableForm();
    this.isFormDisabled = false;
    this.onEStampActiveFlagChange({ checked: this.addEstampDetails.get('eStampActiveFlag').value });
    this.setTagName(this.addEstampDetails.get('eStampCode').value);
  }

  cancelForm(){
    this.isFormDisabled = true;
    this.disableForm();
    this.setDefaultFormValue();
    if (this.loanDocumets.length > 0) {
      this.changeselectedLoanDocument(this.loanDocumets[0],0);
    }
    // this is to make the scroller to move to top, on click of cancelForm it will select first record,
    // hence to move the scroller to top we are calling this scrollTop method this is set to zero to move top.
    document.getElementById('scrollableContent').scrollTop = 0;
  }

  getEstampTags(){
    this.applicationService.getEstampTags(this.application.branchCode).subscribe(response =>{
      this.eStampDetails = response.body;
    })
  }

  setTagName(value){
    if (value === 'OTH') {
      this.addEstampDetails.get('tagName').enable();
    } else {
      this.addEstampDetails.get('tagName').disable();
      this.addEstampDetails.get('tagName').setValue('');
    }
  }

  onEStampActiveFlagChange(event: any) {
    if (event.checked) {
      this.addEstampDetails.get('eStampCode').enable();
      this.addEstampDetails.get('eStampCode').setValidators(ControlValidators.requiredValidator("This field is required"));
      this.addEstampDetails.get('eStampCode').updateValueAndValidity();
    }else{
      this.clearEstampData();
    }
  }

  clearEstampData() {
    this.addEstampDetails.get('eStampCode').setValue('');
    this.addEstampDetails.get('eStampCode').disable();
    this.addEstampDetails.get('eStampCode').clearValidators();
    this.addEstampDetails.get('eStampCode').updateValueAndValidity();
    this.addEstampDetails.get('tagName').setValue('');
    this.addEstampDetails.get('tagName').disable();
  }

  enableForm(){
    this.signersForm.enable();
    this.addNewRecord.enable();
    this.addEstampDetails.enable();
  }

  disableForm(){
    this.signersForm.disable();
    this.addNewRecord.disable();
    this.addEstampDetails.disable();
  }


  //this function will get either this menu item  is editable or readonly
  getMenuItemAccess() {
    this.applicationService
      .getApplicationAccessModifiers(this.application.uid)
      .subscribe((response) => {
        this.applicationAccessSpecifiers = response.body;
        this.menuItemAccessSpecifier = this.applicationAccessSpecifiers.find(
          (accessSpecifier) => accessSpecifier.category === this.menuCode
        );
        if (this.menuItemAccessSpecifier) {
          this.menuItemAllowAccess = this.menuItemAccessSpecifier.allowAccess;
        }
      });
  }

  viewPdf(file){
    this.fileService.fileStreamById(file.fileId,"false").subscribe(res =>{
      window.open(res.data, '_blank');
    })
  }
}
