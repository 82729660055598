import { Component, OnInit, OnChanges, Input, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { LoanApplication, Person, ApplicationAccessSpecifier } from '@app/applications/applications.model';
import { NameValueDto } from '@ig-core/interfaces/name-value-dto';
import { ApplicationService } from '@app/applications/application.service';
import { ReferenceCodeService } from '@app/admin/reference-code/reference-code.service';
import { DateAdapter, MatSnackBar } from '@angular/material';
import { ReferenceCode } from '@app/admin/reference-code/reference-code.model';
import { ValidationService } from '../../../application-validators/validation.service'
import { KycProof, KycProofType } from '../../business/kycProofs/kyc.model';
import { IgFileService, Files } from '@ig-core/form/igFile.service';
import { ImagePreviewDialogService } from '@app/utils/image-preview-dialog/image-preview-dialog.service';
import { ControlValidators } from '../../../application-validators/control-validators.directive';
import { DateFormatPipe } from '@app/utils/date-format.pipe';
import { File, Folder, FolderFilesComposite } from '@app/applications/application-details/loanInformation/loan-folder-files/loan-folder-files.model';
import * as fileSaver from 'file-saver';
import { FileUploadService } from '@app/utils/file-upload/file-upload.service';
import { ConfirmationDialogService } from '@app/utils/confirmation-dialog/confirmation-dialog.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { KycVerificationResponseComposite } from './applicant-kyc-proof.model';
import { KYC_VERIFICATION_MODE_ELECTRONIC, SOURCE_MANUAL } from '@app/constants/data.constants';
@Component({
  selector: 'eng-applicant-kyc',
  templateUrl: 'applicant-kyc-proof.template.html',
  styleUrls: ['../../application-details.styles.scss']
})
export class ApplicantKYCProofsComponent implements OnInit, OnChanges {

  @Input()
  application: LoanApplication;

  @Input()
  applicant: Person;

  @Input()
  menuCode: string;

  applicantKycProofs: KycProof[] = [];
  selectedApplicantKyc: KycProof;
  applicantkycProofTypes: KycProofType[] = [];

  applicantKycForm: FormGroup = new FormGroup({});

  applicantProofDocumentTypes: ReferenceCode[] = [];
  applicantProofTypes: NameValueDto[] = [];

  isApplicantKycValidityFormLoaded: boolean;

  isFormDisabled: boolean;
  showForm: boolean;
  allowAccess: Boolean;
  menuItemAllowAccess: boolean;

  allFilesInFolder: FolderFilesComposite;

  applicationAccessSpecifiers: ApplicationAccessSpecifier[];
  menuItemAccessSpecifier: ApplicationAccessSpecifier;

  eVerificationKycDetails:any;
  selectedDocument: KycProof = {};
  documentVerificationResponse: KycVerificationResponseComposite
  // this is used to set duplicate of applicantKycProofs response value which we get on api call
  applicantKycProofPrestine: KycProof[] = [];

  constructor(private formBuilder: FormBuilder,
    private applicationService: ApplicationService,
    private referenceCodeService: ReferenceCodeService,
    private dateAdapter: DateAdapter<Date>,
    private validationService: ValidationService,
    private _snackbar: MatSnackBar,
    private fileService: IgFileService,
    private customDatepipe: DateFormatPipe,
    private confirmationDialogService: ConfirmationDialogService,
    private imagePreviewDialogService: ImagePreviewDialogService, private fileUploadService: FileUploadService) {
    this.dateAdapter.setLocale('en-IN');
    this.applicantKycForm = this.formBuilder.group({
      documentProof: '',
      documentNumber: '',
      issuingAuthority: '',
      expiryDate: ''
    });
    this.allowAccess = this.applicationService.allowAccess;
  }

  ngOnInit() {
    this.isFormDisabled = true;
    this.applyValidatorsToApplicantKycProofs();
    this.getMenuItemAccess();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.applicant) {
      this.getRefCodes();
      this.fetchApplicantKycProofTypes();
      this.fetchApplicantKycProofs();
    }
  }

  getRefCodes() {
    this.referenceCodeService.getRefCodesForClassifier('person_proof_doc').subscribe((response: any) => {
      this.applicantProofDocumentTypes = response;
      this.referenceCodeService.getShortRefCodes('person_proof_type').subscribe((response: any) => {
        this.applicantProofTypes = response.person_proof_type;
       
      });
    });
  }

  fetchApplicantKycProofs() {
    this.showForm = false;
    this.applicationService.getApplicantKycProofs(this.application.uid, this.applicant.uid)
      .subscribe((response: any) => {
        if (response) {
          this.applicantKycProofs = response.body;
          // taking copy of the response in applicantKycProofPrestine, when click on close we are setting back applicantKycProofPrestine to applicantKycProofs
          this.applicantKycProofPrestine = JSON.parse(JSON.stringify(this.applicantKycProofs));
          this.selectedDocument = this.applicantKycProofs[0];
          this.applicantKycProofs.forEach((element, index) => {
            this.applicantKycProofs[index]["deleteBtnDisabled"] = true;
          });
          this.showForm = (this.applicantKycProofs && this.applicantKycProofs.length > 0);
          if(this.applicantKycProofs.length != 0){
          //if (!this.applicantKycForm.get("applicantKycValidityFormGroup")) {
            this.showForm = true;
            this.buildApplicantKycForm();
            this.setApplicantProofTypes();
          //} 
          //else {
          //   this.changeselectedApplicantKyc(this.applicantKycProofs[0],null,"new")
          // }
        }
        }
      });
  }

  fetchApplicantKycProofTypes() {
    this.applicationService.getApplicantKycProofTypes(this.application.uid,
      this.applicant.uid).subscribe((response: any) => {
        if (response) {
          this.applicantkycProofTypes = response.kycProof;
          this.applicantKycForm.addControl("applicantKycValidityFormGroup",
            this.buildApplicantKycValidityFormGroup(this.applicantkycProofTypes));
        }
      });
  }

  buildApplicantKycForm(applicantKyc?: KycProof) {
    this.documentVerificationResponse = null
    if (this.showForm) {
      if (!applicantKyc) {
        applicantKyc = this.applicantKycProofs[0];
        this.applicantKycProofs[0].deleteBtnDisabled = false;
      }
      this.applicantKycForm.patchValue({
        documentProof: applicantKyc.kycProofDoc,
        documentNumber: applicantKyc.documentNumber,
        issuingAuthority: applicantKyc.issuingAuthority,
        expiryDate: applicantKyc.documentExpiryDate === null|| applicantKyc.documentExpiryDate === undefined?
          undefined : new Date(applicantKyc.documentExpiryDate),

      });
      this.applicantKycForm.controls['expiryDate']
        .setValidators(ControlValidators.minDateValidator(this.application.applicationDate, true, "Expiry Date should be future date as of application date"));
      this.selectedApplicantKyc = applicantKyc;
      this.getSelectedFolderFiles(this.selectedApplicantKyc.fileFolderUid)
      //this.onClickEverify()
      this.applicantKycForm.disable();
      // we are calling getKycDocumentVerificationEvidence only if the selected document is electronically verified,
      // and if it has serviceProvidedData then only we are displaying the electronic verification reference card
      if(this.selectedApplicantKyc.verificationMode === KYC_VERIFICATION_MODE_ELECTRONIC){
        this.applicationService.getKycDocumentVerificationEvidence(this.application.uid,this.selectedApplicantKyc.uid,).subscribe(response =>{
          if(response.body.serviceProviderData){
          this.documentVerificationResponse = response.body
          }
        })
      }
    }
  }

  changeselectedApplicantKyc(applicantKyc, index?: number, from?: string) {
    if (!applicantKyc) applicantKyc = new KycProof();
    this.showForm = true;
    this.applicantKycProofs.forEach((element, i) => {
      this.applicantKycProofs[i].deleteBtnDisabled = true;
    });
    if (from != "new") {
      this.applicantKycProofs[index].deleteBtnDisabled = false;
    }
    this.buildApplicantKycForm(applicantKyc);
    this.selectedDocument = applicantKyc
    this.setApplicantProofTypes();
  }

  addNewKYCDocument(applicantKyc) {
    this.showForm = true;
    this.applicantKycProofs.forEach((element,i) => {
      this.applicantKycProofs[i].deleteBtnDisabled = true;
    });
    if (!applicantKyc) applicantKyc = new KycProof();
    this.buildApplicantKycForm(applicantKyc);
    this.setApplicantProofTypes();
    this.applicantKycForm.enable();
    this.applicantKycForm.controls.applicantKycValidityFormGroup.disable()
    
    this.isFormDisabled = false;
  }

  buildApplicantKycValidityFormGroup(applicantkycProofTypes: KycProofType[]): FormGroup {
    let applicantKycValidityFormGroup = this.formBuilder.group({});
    let selectedDocument = this.applicantProofDocumentTypes.find(
      applicantProofDocumentType => applicantProofDocumentType.code ===
        this.applicantKycForm.value.documentProof);
    applicantkycProofTypes.forEach(applicantKycProofType => {
      // field1 has kyc validity codes with comma seperated values, hence we need to split
      // based on ',' and we need to mark that matching validity code as selected
      // when we do addNewKYCDocument there won't be any matching code because there won't be any document selected, hence selectedDocument won't be available 
      let isSelected = false;
      if (selectedDocument) {
        let selectedFields = selectedDocument.field1 ? selectedDocument.field1.toLocaleUpperCase().split(',').map(field => field.trim()) : [];
        isSelected = selectedFields.includes(applicantKycProofType.kycProofType.toLocaleUpperCase());
      }

      applicantKycValidityFormGroup.addControl(applicantKycProofType.kycProofType,
        this.formBuilder.control(isSelected));
    });
    this.isApplicantKycValidityFormLoaded = true;
    return applicantKycValidityFormGroup;
  }

  setApplicantProofTypes() {
    if(this.applicantkycProofTypes.length !=0 ){
    this.applicantKycForm.removeControl("applicantKycValidityFormGroup");
    this.applicantKycForm.addControl("applicantKycValidityFormGroup",
      this.buildApplicantKycValidityFormGroup(this.applicantkycProofTypes));
    }else{
      this.fetchApplicantKycProofTypes();
    }
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  saveKycProof() {
    this.markFormGroupTouched(this.applicantKycForm)
    if (!this.isFormDisabled && this.applicantKycForm.valid) {
      let isMatched = this.validationService.validateKycDocumentNumber(this.applicantKycForm.value.documentNumber,this.applicantKycForm.value.documentProof,this.applicantProofDocumentTypes)
      if(!isMatched){
            this._snackbar.open("KYC Proof update failed, Invalid document number", "Close", {
              duration: 6000,
            });
            return;
          }
      let kycUid = this.selectedApplicantKyc.uid ? this.selectedApplicantKyc.uid : "0";
      if (!this.selectedApplicantKyc.uid) {
        this.selectedApplicantKyc.linkToType = 'person';
        this.selectedApplicantKyc.linkToUid = this.applicant.uid;
        this.selectedApplicantKyc.contextType = 'application';
        this.selectedApplicantKyc.contextUid = this.application.uid;
      }
      let kycFormFields = this.applicantKycForm.value;
      this.selectedApplicantKyc.kycProofDoc = kycFormFields.documentProof;
      this.selectedApplicantKyc.documentNumber = kycFormFields.documentNumber ? kycFormFields.documentNumber.trim() : null;
      this.selectedApplicantKyc.issuingAuthority = kycFormFields.issuingAuthority;
      this.selectedApplicantKyc.source = SOURCE_MANUAL;
      if (this.applicantKycForm.controls.expiryDate.value != undefined && !isNaN(this.applicantKycForm.controls.expiryDate.value.getTime())) {
        if (this.applicantKycForm.controls.expiryDate.valid) {
          this.selectedApplicantKyc.documentExpiryDate = this.customDatepipe.transform(this.applicantKycForm.controls.expiryDate.value, 'ISODATE');
        }
      } else {
        this.selectedApplicantKyc.documentExpiryDate = null;
      }
      this.applicationService.saveApplicantKycProof(this.application.uid,
        this.applicant.uid, kycUid, this.selectedApplicantKyc).toPromise().then(
          (_success) => {
            this._snackbar.open("KYC Proof updated successfully", "Close", {
              duration: 2000,
            });
            this.isFormDisabled = true;
            this.fetchApplicantKycProofTypes();
            this.fetchApplicantKycProofs();
          }, (failure) => {
            let errormesg = []
            errormesg.push(failure)
            this.applicationService.displayErrorMessages(errormesg);
            console.log(failure);
          }
        );
    }
  }

  editKycform() {
    if(this.selectedApplicantKyc.verifiedFlag == true){
      this.applicantKycForm.disable();
      this._snackbar.open("KYC already verified. Edit not allowed", "close" ,{
        duration: 5000
      })
      return;
    }
    this.applicantKycForm.enable();
    this.isFormDisabled = false;
    this.applicantKycForm.controls.applicantKycValidityFormGroup.disable()
  }

  closeForm() {
    // we are setting back the applicantKycProofPrestine value to applicantKycProofs and building the form to get the previous value.
    this.applicantKycProofs = JSON.parse(JSON.stringify(this.applicantKycProofPrestine));
    this.applicantKycProofs.forEach((element, i) => {
      this.applicantKycProofs[i].deleteBtnDisabled = true;
    });
    if (this.applicantKycProofs && this.applicantKycProofs.length > 0) {
      this.buildApplicantKycForm();
      this.setApplicantProofTypes();
    } else {
      this.showForm = false;
    }
    this.applicantKycForm.disable();
    this.isFormDisabled = true;
  }
  //when applicantKycValidityFormGroup is called
  get applicantKycValidityFormGroup(): any {
    return this.applicantKycForm.get('applicantKycValidityFormGroup');
  }
  //this function will apply validators to form group
  applyValidatorsToApplicantKycProofs() {
    this.validationService.applyValidationRules(this.applicantKycForm, "ApplicantKycProofs").then((controlValidators) => {
    }).catch(() => {
    })
  }


  //this function open up the image viewer dailog box
  openImagePreviewDialog() {
    this.imagePreviewDialogService.open({ "images": this.allFilesInFolder })
  }

  viewPdf(file){
    this.fileService.fileStreamById(file.fileId,"false").subscribe(res =>{
      window.open(res.data, '_blank');
    })
  }

  //this function will get either this menu item  is editable or readonly 
  getMenuItemAccess() {
    this.applicationService.getApplicationAccessModifiers(this.application.uid)
      .subscribe((response) => {
        this.applicationAccessSpecifiers = response.body;
        this.menuItemAccessSpecifier = this.applicationAccessSpecifiers
          .find(accessSpecifier => accessSpecifier.category === this.menuCode);
        if (this.menuItemAccessSpecifier) {
          this.menuItemAllowAccess = this.menuItemAccessSpecifier.allowAccess;
        }
      });
  }

  //this function is called on click of expirydate input
  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    if (!this.isFormDisabled) {
      if (event.value == null) {
        this.applicantKycForm.controls.expiryDate.patchValue(undefined);
      }
    }
  }


  //this function open up the image upload dailog box
  //if file upload should allow single and multiple file upload, we need to pass "uploadType":"multiple"
  openImageFileUploadDialog() {
    if (this.selectedApplicantKyc.fileFolderUid) {
      this.fileUploadService.open({ "uploadType": "multiple", "folderUid": this.selectedApplicantKyc.fileFolderUid, "applicationUid": this.application.uid, "files": this.allFilesInFolder.fileInfo })
      this.fileUploadService.confirmed().subscribe(data => {
        this.getSelectedFolderFiles(data.folderUid)
      })

    } else {
      this._snackbar.open("Please add a folder to upload files", "Close", {
        duration: 5000,
      });
    }
  }

  getSelectedFolderFiles(folderUid) {
    if (folderUid) {
      this.applicationService.getAllFilesInApplicationFolder(this.application.uid, folderUid).subscribe((response: any) => {
        if (response) {
          this.allFilesInFolder = response.body;
          this.allFilesInFolder.fileInfo.forEach(element => {
            if (element.fileSize) {
              element["convertedFileSize"] = this.applicationService.bytesToSize(element.fileSize)
            }
          });
        }
      },error =>{
        this.allFilesInFolder={
          folderInfo:{},
          fileInfo:[]
        }
      });
    }
  }

  //this function will call onclick of download icon in file's section
  downloadFile(file) {
    this.applicationService.downloadFileFromApplicationFolder(this.application.uid, file.folderUid, file.fileId).subscribe(image => {
      const blob = new Blob([image.data], { type: file.type });
      fileSaver.saveAs(blob, file.fileName);
      this._snackbar.open("File has been downloaded successfully", "Close", {
        duration: 5000,
      });
    })
  }


  //this function will be called on click of delete icon on Kyc
  deleteKyc(selectedApplicantKyc) {
    this.applicationService.kycDelete(selectedApplicantKyc.uid).subscribe((response) => {
      if(response.body.status == "success") {
      this._snackbar.open("KYC Proof deleted successfully", "Close", {
        duration: 2000,
      });
      this.fetchApplicantKycProofTypes();
      this.fetchApplicantKycProofs();
    } else {
      let errormesg = []
      errormesg.push("KYC Proof deleted failed")
      errormesg.push(response.body.message)
      this.applicationService.displayErrorMessages(errormesg);
    }}, (failure) => {
      let errormesg = []
      errormesg.push("KYC Proof deleted failed")
      errormesg.push(failure)
      this.applicationService.displayErrorMessages(errormesg);
    });
  }

  openDeleteConfirmation() {
    this.confirmationDialogService.open({ "btnCancelText": "Cancel", "btnConfirmText": "Delete", "message": "Are you sure you want to delete this KYC Proof?" })
    this.confirmationDialogService.confirmed().subscribe(data => {
      if (data) {
        this.deleteKyc(this.selectedApplicantKyc)
      }
    })
  }

  //this function will be called on click of delete icon on file list
deleteFile(selectedFile){
  this.applicationService.fileDelete(this.application.uid, selectedFile.uid).subscribe((response) => {
    if(response.body.status == "success") {
    this._snackbar.open("File deleted successfully", "Close", {
      duration: 2000,
    });
    this.getSelectedFolderFiles(this.selectedApplicantKyc.fileFolderUid);
  } else {
    let errormesg =[]
    errormesg.push("File deleted failed")
    errormesg.push(response.body.message)
    this.applicationService.displayErrorMessages(errormesg);
  }}, (failure) => {
    let errormesg =[]
    errormesg.push("File deleted failed")
    errormesg.push(failure)
    this.applicationService.displayErrorMessages(errormesg);
  });
}

  openfileDeleteConfirmation(selectedFile){
      this.confirmationDialogService.open({ "btnCancelText":"Cancel","btnConfirmText":"Delete","message":"Are you sure you want to delete this File?"})
      this.confirmationDialogService.confirmed().subscribe(data=>{
        if(data){
        this.deleteFile(selectedFile)
        }
      })
    
  }

}