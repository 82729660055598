import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse, HttpEvent, HttpRequest, HttpHeaders } from '@angular/common/http';
import { SalesBoardSummary } from './dashboard.model';
import { FieldBoard, FieldSearchCriteria } from './field-board/field-board.model';
import { Observable, of } from 'rxjs';
@Injectable({
    providedIn: 'root'
})
export class DashboardService {
    private APIv1 = 'api/v1/';
    private SearchCriteria: any = null;
    constructor(private http: HttpClient) { }

    getDashboradDataStagewise(branches, login,product,leadSource) {
        let params = new HttpParams();
        params = params.set('branches', branches);
        params = params.set('login', login);
        params = params.set('productType', product);
        params = params.set('leadSource', leadSource);
        return this.http.get(this.APIv1 + 'dashboard/applications/stagewise', { 
            params,
            observe: 'response' });
    }

    getDashboradDataTATmatrix(branches, login) {
        let params = new HttpParams();
        params = params.set('branches', branches);
        params = params.set('login', login);
        return this.http.get(this.APIv1 + 'dashboard/applications/tatmatrix', { 
            params,
            observe: 'response'
        });
    }
    updateSearchCriteria(data) {
        this.SearchCriteria = data
    }

    getSearchCriteria() {
        return this.SearchCriteria
    }

    getSalsBoardDetails(branches, login) {
        let params = new HttpParams();
        params = params.set('branches', branches);
        params = params.set('login', login);
        return this.http.get<SalesBoardSummary>(this.APIv1 + 'dashboard/applications/salesboard', { 
            params,
            observe: 'response' 
        });
    }

    findFieldBoard(branches, login): Observable<HttpResponse<any>> {
        let params = new HttpParams();
        params = params.set('branches', branches);
        params = params.set('login', login);


        return this.http.get<FieldBoard[]>(this.APIv1 + 'dashboard/applications/fieldBoard', {
            params,
            observe: 'response'
        });
    }


}
