
export class LoanDocumentDTO {
    constructor(
      public uid?: string,
      public name?: string,
      public docketName?: string,
      public signedDocumentUid?: string,
      public status?: string,
      public linkToType?:string,
      public linkToId?: string,
      public linkToUid?: string,
      public contextType?: string,
      public contextId?: string,
      public contextUid?: string,
      public version?: number,
      public refreshBtnDisabled?: boolean,//added for client side use this paramenter will not come from server
      public folderUid?: string,
      public category ?: string,
      public estampCode ?: string,
      public estampTag ?: string
    ) { }
  }

  export class SignerDTO {
    constructor(
      public uid?: string,
      public name?: string,
      public mobileNumber?: string,
      public type?: string,
      public status?: string,
      public activeFlag?: boolean,
      public linkToType?:string,
      public linkToId?: string,
      public linkToUid?: string,
      public contextType?: string,
      public contextId?: string,
      public contextUid?: string,
      public version?: number
    ) { }
  }

  export class LoanDocumentSignersDto {
    constructor(
      public signersList ?: SignerDTO[],
      public category ?: string,
      public estampCode ?: string,
      public estampTag ?: string,
    ){}
  }
  