import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormErrorModel } from '@ig-core/form/form-error.model';
import { IgFormService } from '@ig-core/form/form.service';
import { IgPatternValidator } from '@ig-core/form/additional-validators/ig-pattern-validator';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { ReferenceCode } from '../reference-code.model';
import { ReferenceCodeService } from '../reference-code.service';
import { NameValueDto } from '@ig-core/interfaces/name-value-dto';

@Component({
  selector: 'app-reference-code-editor',
  templateUrl: './reference-code-editor.component.html',
  styleUrls: ['../reference-code.component.scss']
})

export class ReferenceCodeEditorComponent implements OnInit, OnDestroy {

  referenceCode: ReferenceCode;
  editable = true;
  field1Lable = "Field 1";
  field2Lable = "Field 2";
  field3Lable = "Field 3";
  field4Lable = "Field 4";
  field5Lable = "Field 5";

  public referenceCodeEditorForm: FormGroup;
  public formError: FormErrorModel;
  public formErrors = {
    id: '',
    version: '',
    classifier: '',
    name: '',
    code: '',
    parentReferenceCode: '',
    status: '',
    field1: '',
    field2: '',
    field3: '',
    field4: '',
    field5: ''
  };

  public classifierObservable$;
  public parentCodes: NameValueDto[] = [];
  
  showRelatedRefCodeForClassifier: ReferenceCode[];
  constructor(
    private referenceCodeService: ReferenceCodeService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private _snackbar: MatSnackBar,
    private igFormService: IgFormService
  ) {
    this.activatedRoute.data
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data.referenceCode === undefined) {
          this.referenceCode = {};
        } else {
          this.referenceCode = data.referenceCode;
        }
        this.editable = data.editable;
      });
    this.classifierObservable$ = this.referenceCodeService.getClassifiers();
  }

  ngOnInit() {
    this.setFieldLabelName();
    this.buildForm();
    this.formError = new FormErrorModel(false, '');
    if (!this.editable) {
      this.referenceCodeEditorForm.disable();
      this.referenceCodeService.getRefCodesForClassifier(this.referenceCode.classifier).subscribe(response =>{
        this.showRelatedRefCodeForClassifier = response
        this.showRelatedRefCodeForClassifier = this.showRelatedRefCodeForClassifier.filter(refCode => refCode.code !== this.referenceCode.code)
      })
    }
    if(this.referenceCode.classifier){
      this.loadParentCodes(this.referenceCode.classifier);
    }
  }

  loadParentCodes(classifier) {
    this.referenceCodeService
      .getParentCodes(classifier)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(response => {
        this.parentCodes = response;
      });
  }

  ngOnDestroy(): void {}


  setFieldLabelName() {
    // for same classifier that is multi-type classifier like "settings" we cannot give field_label name,
    // so for such classifier we are creating field_label code as "classifier:code" hence when we fetch fieldLabels 
    // we are comparing with fieldLabels code with classifier:code as well to show field label names.
    this.referenceCodeService.getRefCodesForClassifier('field_labels').subscribe((response: any) => {
      const fieldLabels = response;
      fieldLabels.forEach(val => {
        if (val.code == this.referenceCode.classifier || val.code == this.referenceCode.classifier + ":" + this.referenceCode.code) {
          this.field1Lable = val.field1 ? val.field1 : "Field 1";
          this.field2Lable = val.field2 ? val.field2 : "Field 2";
          this.field3Lable = val.field3 ? val.field3 : "Field 3";
          this.field4Lable = val.field4 ? val.field4 : "Field 4";
          this.field5Lable = val.field5 ? val.field5 : "Field 5";
        }
      })
    });
  }

  buildForm() {
    this.referenceCodeEditorForm = this.formBuilder.group({
      id: [this.referenceCode.id, []],
      version: [this.referenceCode.version, []],
      classifier: [this.referenceCode.classifier, [Validators.required]],
      name: [this.referenceCode.name, [Validators.required]],
      code: [this.referenceCode.code, [Validators.required]],
      parentReferenceCode: [this.referenceCode.parentReferenceCode, []],
      status: [this.referenceCode.status ? this.referenceCode.status : '0', []],
      field1: [this.referenceCode.field1, []],
      field2: [this.referenceCode.field2, []],
      field3: [this.referenceCode.field3, []],
      field4: [this.referenceCode.field4, []],
      field5: [this.referenceCode.field5, []]
    });

    this.referenceCodeEditorForm.valueChanges
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        this.formErrors = this.igFormService.validateForm(
          this.referenceCodeEditorForm,
          this.formErrors,
          true
        );
      });
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  saveReferenceCode() {
    this.markFormGroupTouched(this.referenceCodeEditorForm)
    if (this.referenceCodeEditorForm.valid) {
      let referenceCodeFormFields = this.referenceCodeEditorForm.value;
      this.referenceCode.id = referenceCodeFormFields.id;
      this.referenceCode.version = referenceCodeFormFields.version;
      this.referenceCode.classifier = referenceCodeFormFields.classifier;
      this.referenceCode.name = referenceCodeFormFields.name;
      this.referenceCode.code = referenceCodeFormFields.code;
      this.referenceCode.parentReferenceCode = referenceCodeFormFields.parentReferenceCode;
      this.referenceCode.status = referenceCodeFormFields.status;
      this.referenceCode.field1 = referenceCodeFormFields.field1 !== '' ? referenceCodeFormFields.field1 : null;
      this.referenceCode.field2 = referenceCodeFormFields.field2 !== '' ? referenceCodeFormFields.field2 : null;
      this.referenceCode.field3 = referenceCodeFormFields.field3 !== '' ? referenceCodeFormFields.field3 : null;
      this.referenceCode.field4 = referenceCodeFormFields.field4 !== '' ? referenceCodeFormFields.field4 : null;
      this.referenceCode.field5 = referenceCodeFormFields.field5 !== '' ? referenceCodeFormFields.field5 : null;
      this.referenceCodeService.saveReferenceCode(this.referenceCode).toPromise().then(
          (_success) => {
            if (this.referenceCode.id) {
              this._snackbar.open("Reference Code updated successfully", "Close", {
                duration: 2000,
              });
              this.router.navigate(['../../'], { relativeTo: this.activatedRoute });
            } else {
              this._snackbar.open("Reference Code created successfully", "Close", {
                duration: 2000,
              });
              this.router.navigate(['../'], { relativeTo: this.activatedRoute });
            }
          }, (failure) => {
            this._snackbar.open("Reference Code update failed, " + failure, "Close", {
              duration: 2000,
            })
            console.log(failure);
          }
        );
    }
  }

/* 
  onSubmit() {
    this.formError = new FormErrorModel(false, '');

    // mark all fields as touched
    this.igFormService.markFormGroupTouched(this.referenceCodeEditorForm);

    if (this.referenceCodeEditorForm.valid) {
      this.referenceCodeService
        .saveReferenceCode(this.referenceCodeEditorForm.value)
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe(
          response => this.onSubmitSuccess(response),
          response => this.onSubmitError(response)
        );
    } else {
      this.formErrors = this.igFormService.validateForm(
        this.referenceCodeEditorForm,
        this.formErrors,
        false
      );
      return false;
    }
  }

  private onSubmitSuccess(response) {
      let msg = '';
      if(this.referenceCode.id) {
        msg = `Updated referenceCode ${this.referenceCode.id} successfully`;
      } else {
        msg = `Created referenceCode successfully`;
      }
      this.snackBar.open( msg, 'Close');
    this.goToReferenceCodeListPage();
  }

  private onSubmitError(response) {
    const errorModel = this.igFormService.handleServerError(
      this.referenceCodeEditorForm,
      this.formErrors,
      response.error
    );
    if (errorModel && errorModel.isValidationError) {
      this.formErrors = errorModel.formErrors;
    }
  }
 */
  goToReferenceCodeListPage() {
    if (this.referenceCode.id) {
      this.router.navigate(['../../'], { relativeTo: this.activatedRoute });
    } else {
      this.router.navigate(['../'], { relativeTo: this.activatedRoute });
    }
  }

  // this method is called when click of related refcode that refcode details will be shown in above card
  onRelatedRefCodeSelect(selectedRefcode){
    this.referenceCode = selectedRefcode
    this.ngOnInit()
  }

  // on click of edit it will redirect to edit component
  goToReferenceCodeEditPage(){
    this.router.navigate(['../../update/' + this.referenceCode.id], { relativeTo: this.activatedRoute });
  }
}
